import React from "react";

function Member() {
  return (
    <div>
      <section class="pt-32 max-w-6xl mx-auto px-4 sm:px-6 lg:px-4 ">
        <div class="text-center pb-12">
          <h1 class="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-rose-700">
            Team & Leadership
          </h1>
        </div>
        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div class="w-full bg-white shadow-lg rounded-xl hover:shadow-2xl p-12 flex flex-col justify-center items-center">
            <div class="mb-8">
              <img
                class="object-center object-cover rounded-full h-36 w-36"
                src="./image/IMG_8982.jpg"
                alt="photos"
              />
            </div>
            <div class="text-center">
              <p class="text-xl text-slate-800 font-bold mb-2">
                Bekure Tiguhan Iyasu Debebe
              </p>
              <p class="text-base text-gray-400 font-normal"> Board Chairman</p>
            </div>
          </div>
          <div class="w-full bg-white shadow-lg rounded-xl hover:shadow-2xl p-12 flex flex-col justify-center items-center">
            <div class="mb-8">
              <img
                class="object-center object-cover rounded-full h-36 w-36"
                src="./image/IMG_8977.jpg"
                alt="photos"
              />
            </div>
            <div class="text-center">
              <p class="text-xl text-slate-800 font-bold mb-2">
                Mr Mekbib Ngussie
              </p>
              <p class="text-base text-gray-400 font-normal">
                Assistant Board Chairman
              </p>
            </div>
          </div>
          <div class="w-full bg-white shadow-lg rounded-xl hover:shadow-2xl p-12 flex flex-col justify-center items-center">
            <div class="mb-8">
              <img
                class="object-center object-cover rounded-full h-36 w-36"
                src="./image/Mr fekeru.jpg"
                alt="photos"
              />
            </div>
            <div class="text-center">
              <p class="text-xl text-slate-800 font-bold mb-2">
                Mr Fikiru Aytegeb
              </p>
              <p class="text-base text-gray-400 font-normal">
                Board Secretary and General Manager
              </p>
            </div>
          </div>
          <div class="w-full bg-white shadow-lg rounded-xl hover:shadow-2xl p-12 flex flex-col justify-center items-center">
            <div class="mb-8">
              <img
                class="object-center object-cover rounded-full h-36 w-36"
                src="./image/Mr melaku.jpg"
                alt="photos"
              />
            </div>
            <div class="text-center">
              <p class="text-xl text-slate-800 font-bold mb-2">
                Mr Melaku Tilahun
              </p>
              <p class="text-base text-gray-400 font-normal">Board Member</p>
            </div>
          </div>
          <div class="w-full bg-white shadow-lg rounded-xl hover:shadow-2xl p-12 flex flex-col justify-center items-center">
            <div class="mb-8">
              <img
                class="object-center object-cover rounded-full h-36 w-36"
                src="./image/Dn Dr yonas.jpg"
                alt="photos"
              />
            </div>
            <div class="text-center">
              <p class="text-xl text-slate-800 font-bold mb-2">
                {" "}
                Dn. Dr. Yonas Libu
              </p>
              <p class="text-base text-gray-400 font-normal">Board Member</p>
            </div>
          </div>{" "}
          <div class="w-full bg-white shadow-lg rounded-xl hover:shadow-2xl p-12 flex flex-col justify-center items-center">
            <div class="mb-8">
              <img
                class="object-center object-cover rounded-full h-36 w-36"
                src="./image/yilmas.jpg"
                alt="photos"
              />
            </div>
            <div class="text-center">
              <p class="text-xl text-slate-800 font-bold mb-2">
                Liqe Mezmeran Yilma hailu
              </p>
              <p class="text-base text-gray-400 font-normal">
                {" "}
                Honorary Ambassador
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Member;
