import React from "react";

function QRCodePopup({
  showQRCode,
  toggleQRCode,
  fname,
  lname,
  email,
  amount,
  tx_ref,
  public_key,
}) {
  //   const [showQRCode, setShowQRCode] = useState(false);

  //   const toggleQRCode = () => {
  //     setShowQRCode(!showQRCode);
  //   };

  return (
    <div className="flex justify-center items-center ">
      {showQRCode && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <form method="POST" action="https://api.chapa.co/v1/hosted/pay">
            <input type="hidden" name="public_key" value={public_key} />
            <input type="hidden" name="tx_ref" value={tx_ref} />
            <input type="hidden" name="amount" value={amount} />
            <input type="hidden" name="currency" value="ETB" />
            <input type="hidden" name="email" value={email} />
            <input type="hidden" name="first_name" value={fname} />
            <input type="hidden" name="last_name" value={lname} />
            <input type="hidden" name="title" value="Let us do this" />
            <input
              type="hidden"
              name="description"
              value="Paying with Confidence with cha"
            />
            <input
              type="hidden"
              name="logo"
              value="https://chapa.link/asset/images/chapa_swirl.svg"
            />
            <input
              type="hidden"
              name="callback_url"
              value="https://api.chapa.co/v1/transaction/initialize"
            />
            <input
              type="hidden"
              name="return_url"
              value="https://pms-medical-project.netlify.app/drugstore"
            />
            <input type="hidden" name="meta[title]" value="test" />

            <div className="bg-white p-8 rounded shadow-lg">
              {/* <div className="text-center mb-4">
                <p className="text-lg font-semibold">Scan QR Code</p>
              </div>
              <div className="flex justify-center">
                <QRCode
                  type="submit"
                  value="http://checkout.chapa.co/checkout/web/payment/PL-ScmZ73R8BsDe"
                  size={200}
                />
              </div> */}
              <div className="flex justify-center">
                <button
                  onClick={toggleQRCode}
                  className="mt-4 text-white py-2 px-4 mr-4  rounded bg-gray-700 hover:bg-gray-800 shadow hover:shadow-lg font-medium transition transform hover:-translate-y-0.5"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="mt-4 text-white py-2 px-4 mr-4  rounded bg-green-700 hover:bg-green-800 shadow hover:shadow-lg font-medium transition transform hover:-translate-y-0.5"
                >
                  Pay Now
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default QRCodePopup;
