import React from "react";
import { SplideSlide } from "@splidejs/react-splide";
import Navbarss from "../navbars/Navbarss";
import { TbTargetArrow } from "react-icons/tb";
import { GrAchievement } from "react-icons/gr";
import { BiTask } from "react-icons/bi";

function Network() {
  return (
    <div>
      <Navbarss />

      <SplideSlide>
        <div class=" w-full ">
          <span
            id="blackOverlay"
            class="w-full h-full absolute opacity-50 bg-black"
          ></span>
          <img src="./image/bak.jpg" alt="telebirr" class="h-100 w-full " />
          <div class="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center text-gray-500 sm:gap-12 dark:text-gray-400">
            <h1 class="font-extrabold text-white text-3xl md:text-4xl lg:text-5xl font-heading shadow-2xl  ">
              Digital Business Network
            </h1>
            <p className="p-8 text-white text-center">
              {" "}
              This program focuses on creating a digital business network for
              Orthodox youth. This will be done by creating an active online
              <br></br> database that records members’ business activities and
              sparks business relationships between individuals and
              institutions.
            </p>
          </div>
        </div>
      </SplideSlide>

      <section class=" dark:bg-gray-900">
        <div class="max-w-screen-xl px-4 py-4 mx-auto text-center lg:py-16 lg:px-6">
          <div class="text-center pb-12 rel">
            <h1 class="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-rose-700">
              Challenge
            </h1>
          </div>
          <figure class="max-w-screen-md mx-auto">
            <p class="text-md  text-gray-900 dark:text-white ">
              Currently, finding work is difficult anywhere in the world. The
              unequal distribution of national wealth and the highest proportion
              of young people finding employment, particularly in sub-Saharan
              nations like Ethiopia, became matters of fortune. Youths turn to
              gambling, addiction, corruption, and a lack of hope in their lives
              because there aren't enough work possibilities for them.
            </p>
            <p className="my-4">
              Contrarily, many organizations are looking for qualified
              individuals who can complete the necessary responsibilities. The
              best way to find people to fill the aforementioned gap is through
              using business networks, especially those in their digital form.
            </p>
          </figure>
        </div>
      </section>
      <section class="  pt-8">
        <div class=" lg:col-span-7 flex justify-center items-center mt-6">
          <p class="max-w-2xl mb-4 text-2xl font-extrabold leading-none md:text-2xl xl:text-2xl dark:text-white ">
            <a href="/join" className="text-rose-800 font-normal text-center">
              Support Our Project
            </a>
          </p>
        </div>
        <div class="container mx-auto px-4 mb-6">
          <div class="flex flex-wrap">
            <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
              <div class="max-w-md bg-white rounded-xl overflow-hidden cursor-pointer shadow-lg hover:shadow-2xl hover:scale-105 transform transition-all duration-500 p-2">
                <div class="px-4 py-5 flex-auto">
                  <div class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                    <BiTask className="w-8 h-8" />
                  </div>
                  <h6 class="text-xl font-semibold">What we do</h6>
                  <p class="mt-2 mb-4 text-blueGray-500">
                    Gebr-Heir creates a database of prospective employers,
                    partners, and orthodox youngsters and connects talented and
                    open-minded orthodox kids with the employers. It reduces the
                    financial and time expenses for young people to obtain
                    employment and enter the market. This helps companies
                    acquire honest, moral, and qualified labor, which will be a
                    valuable asset.
                  </p>
                </div>
              </div>
            </div>
            <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
              <div class="max-w-md bg-white rounded-xl overflow-hidden cursor-pointer shadow-lg hover:shadow-2xl hover:scale-105 transform transition-all duration-500 p-2">
                <div class="px-4 py-5 flex-auto">
                  <div class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-rose-400">
                    <GrAchievement className="w-8 h-8 " />
                  </div>
                  <h6 class="text-xl font-semibold">Achievement </h6>
                  <p class="mt-2 mb-24 text-blueGray-500">
                    Gebr-Hair has connected approximately 150 competent and
                    moral young people with their appropriate employers in the
                    recent months.
                  </p>
                </div>
              </div>
            </div>
            <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center ">
              <div class="max-w-md bg-white rounded-xl overflow-hidden cursor-pointer shadow-lg hover:shadow-2xl hover:scale-105 transform transition-all duration-500 p-2">
                <div class="px-4 py-5 flex-auto">
                  <div class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                    <TbTargetArrow className="w-8 h-8" />
                  </div>
                  <h6 class="text-xl font-semibold">2023/24 target</h6>
                  <p class="mt-2 mb-28 text-blueGray-500">
                    Through the digital business network database maintained by
                    Gebr-Hairs, we plan to connect 2170 youngsters with possible
                    employers in the coming year.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Network;
