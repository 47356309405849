import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// import { removeTokenFromLocalStorage } from "../../../lib/common";
// import { APP_ROUTES } from "../../../utils/constants";

function Navbarss() {
  const navigate = useNavigate();

  function nav() {
    navigate("/join");
  }

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  const [isProgramsSubMenuOpen, setIsProgramsSubMenuOpen] = useState(false);

  return (
    <nav className="fixed z-50 w-full shadow-lg bg-white border-gray-200 px-4 lg:px-6 py-4 dark:bg-gray-800">
      <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
        <a href="/" className="flex items-center ">
          <img
            src="/image/1 png.png"
            className="mr-3 h-16 "
            alt="gebrher Tour"
          />
        </a>
        <div className="flex items-center lg:order-2">
          {/* Log in Button */}
          {/* <button
            href="/login"
            className="text-rose-900 hover:text-white border border-rose-900 hover:bg-rose-900 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={() => {
              removeTokenFromLocalStorage();
              navigate(APP_ROUTES.SIGN_IN);
            }}
          >
            Log in
          </button> */}

          {/* Donate Button */}
          <button
            className="text-white bg-rose-900 border hover:border-rose-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            onClick={nav}
          >
            Donate
          </button>

          {/* Mobile Menu Toggle Button */}
          <button
            type="button"
            className={`inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden ${
              isMobileMenuOpen
                ? "hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                : "hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            }`}
            onClick={toggleMobileMenu}
            aria-controls="mobile-menu-2"
            aria-expanded={isMobileMenuOpen}
          >
            {/* Mobile Menu Icon */}
            <svg
              className={`w-6 h-6 ${isMobileMenuOpen ? "hidden" : "block"}`}
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              {/* Hamburger Icon */}
              <path
                fillRule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clipRule="evenodd"
              ></path>
            </svg>
            {/* Close Icon */}
            <svg
              className={`w-6 h-6 ${isMobileMenuOpen ? "block" : "hidden"}`}
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              {/* Close Icon Path */}
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>

        {/* Desktop Menu */}
        <div
          className={`justify-between items-center w-full lg:flex lg:w-auto lg:order-1 ${
            isMobileMenuOpen ? "block" : "hidden"
          }`}
          id="mobile-menu-2"
        >
          <ul className="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
            <li>
              <a
                href="/"
                className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-rose-900 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
              >
                Home
              </a>
            </li>
            <li>
              <a
                href="/about"
                className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-rose-900 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
              >
                About
              </a>
            </li>
            <li>
              <a
                href="/program"
                className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-rose-900 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                onMouseEnter={() => setIsProgramsSubMenuOpen(true)}
              >
                Programs
              </a>
              {/* <span className="">
                <AiOutlineDown
                  className={`w-6 h-6 ${isMobileMenuOpen ? "hidden" : "block"}`}
                  onMouseEnter={() => setIsProgramsSubMenuOpen(true)}
                  // onMouseLeave={() => setIsProgramsSubMenuOpen(false)}
                />
              </span> */}
              <div
                className={`absolute mt-4 bg-white shadow-lg rounded-lg ${
                  isProgramsSubMenuOpen ? "block" : "hidden"
                }`}
                onMouseEnter={() => setIsProgramsSubMenuOpen(true)}
                onMouseLeave={() => setIsProgramsSubMenuOpen(false)}
              >
                <a
                  href="/masstrain"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                  onMouseEnter={() => setIsProgramsSubMenuOpen(true)}
                >
                  Mass Training
                </a>
                <a
                  href="/vocationatrain"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                  onMouseEnter={() => setIsProgramsSubMenuOpen(true)}
                >
                  Vocational Training
                </a>
                <a
                  href="/seeding"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                  onMouseEnter={() => setIsProgramsSubMenuOpen(true)}
                >
                  Seed Funding
                </a>
                <a
                  href="/businessnetwork"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                  onMouseEnter={() => setIsProgramsSubMenuOpen(true)}
                >
                  Digital Business Network
                </a>
                {/* Add more submenu options as needed */}
              </div>
            </li>

            <li>
              <a
                href="/news"
                className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-rose-900 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
              >
                News
              </a>
            </li>
            <li>
              <a
                href="/gallery"
                className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-rose-900 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
              >
                Gallery
              </a>
            </li>
            <li>
              <a
                href="/contactus"
                className="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-rose-900 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbarss;
