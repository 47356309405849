import { Routes, Route } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { useRef, useState } from "react";
import "./App.css";
import Profile from "./component/Basic/Profile";
import MainNav from "./component/Admin/Dashboard/MainNav";
import Contactus from "./component/User Page/Contact us/Contactus";
import Dash from "./component/Admin/Dashboard/Dash";
import Home2 from "./component/User Page/home/Home2";
// import jwt_decode from "jwt-decode";
import About from "./component/User Page/About/About";
import Program from "./component/User Page/program/Program";
import JoinUs from "./component/User Page/Joinus/JoinUs";
import Gallery from "./component/User Page/Gallery/Gallery";
import News from "./component/User Page/News/News";
import MassTrain from "./component/User Page/program/MassTrain";
import Vocation from "./component/User Page/program/Vocation";
import Seeding from "./component/User Page/program/Seeding";
import Network from "./component/User Page/program/Network";
import AddPerson from "./component/User Page/Joinus/AddPerson";
import PageCheck from "./component/User Page/Joinus/Check Out/PageCheck";

function App() {
  const location = useLocation();
  const [showSidebar, setShowSidebar] = useState(true);
  const ref = useRef(null);
  // const [role, setRole] = useState("");

  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   if (token) {
  //     const decodedToken = jwt_decode(token);
  //     setRole(decodedToken.role);
  //   }
  // }, []);

  (function (w, d) {
    w.CollectId = "650d51fd8a3fde15c3ecf123";
    var h = d.head || d.getElementsByTagName("head")[0];
    var s = d.createElement("script");
    s.setAttribute("type", "text/javascript");
    s.async = true;
    s.setAttribute("src", "https://collectcdn.com/launcher.js");
    h.appendChild(s);
  })(window, document);

  return (
    <div className="App w-full flex  bg-gray-50">
      {location.pathname !== "/Trip" &&
        // location.pathname !== "/details/:id" &&
        location.pathname !== "/contactus" &&
        location.pathname !== "/" &&
        location.pathname !== "/about" &&
        location.pathname !== "/hotel" &&
        location.pathname !== "/profile" &&
        location.pathname !== "/login" &&
        location.pathname !== "/program" &&
        location.pathname !== "/home2" &&
        location.pathname !== "/popup" &&
        location.pathname !== "/join" &&
        location.pathname !== "/gallery" &&
        location.pathname !== "/masstrain" &&
        location.pathname !== "/vocationatrain" &&
        location.pathname !== "/seeding" &&
        location.pathname !== "/businessnetwork" &&
        location.pathname !== "/try" &&
        location.pathname !== "/news" &&
        location.pathname !== "/return" &&
        location.pathname !== "/sponsor" &&
        location.pathname !== "/addperson" &&
        //
        !location.pathname.startsWith("/attraction") &&
        !location.pathname.startsWith("/tripbook") &&
        location.pathname !== "/home" && (
          <div
            ref={ref}
            className={`md:w-[20%]  h-[100vh]  shadow top-0 ${
              showSidebar ? "flex fixed md:sticky" : "hidden"
            }`}
          >
            <MainNav
              showSidebar={showSidebar}
              setShowSidebar={setShowSidebar}
            />
          </div>
        )}

      <div
        className={`w-full md:w-[90%]  h-[100vh] overflow-auto ${
          !showSidebar && "md:w-full"
        } ${
          (location.pathname === "/Trip" ||
            // location.pathname === "/details/:id" ||
            location.pathname === "/contactus" ||
            location.pathname === "/about" ||
            location.pathname === "/" ||
            location.pathname === "/hotel" ||
            location.pathname === "/profile" ||
            location.pathname === "/program" ||
            location.pathname === "/login" ||
            location.pathname === "/home2" ||
            location.pathname === "/return" ||
            location.pathname === "/tripbook/:id" ||
            location.pathname === "/popup" ||
            location.pathname === "/join" ||
            location.pathname === "/gallery" ||
            location.pathname === "/masstrain" ||
            location.pathname === "/vocationatrain" ||
            location.pathname === "/seeding" ||
            location.pathname === "/businessnetwork" ||
            location.pathname === "/news" ||
            location.pathname === "/try" ||
            location.pathname === "/sponsor" ||
            location.pathname === "/attraction/:id" ||
            location.pathname === "/addperson" ||
            location.pathname === "/home") &&
          "md:w-full"
        }`}
      >
        <div className="sticky top-0 "></div>

        <Routes>
          {/* {role === "admin" && ( */}
          <Route exact path="/dashboard" element={<Dash />} />
          {/* )} */}
        </Routes>

        <Routes>
          <Route exact path="/profile" element={<Profile />} />
        </Routes>

        {/* <Routes>
          <Route exact path="/login" element={<Login />} />
        </Routes> */}
        <Routes>
          <Route exact path="/about" element={<About />} />
        </Routes>
        <Routes>
          <Route exact path="/join" element={<JoinUs />} />
        </Routes>
        <Routes>
          <Route exact path="/gallery" element={<Gallery />} />
        </Routes>
        <Routes>
          <Route exact path="/news" element={<News />} />
        </Routes>

        <Routes>
          <Route exact path="/program" element={<Program />} />
        </Routes>
        <Routes>
          <Route exact path="/masstrain" element={<MassTrain />} />
        </Routes>
        <Routes>
          <Route exact path="/vocationatrain" element={<Vocation />} />
        </Routes>
        <Routes>
          <Route exact path="/seeding" element={<Seeding />} />
        </Routes>
        <Routes>
          <Route exact path="/businessnetwork" element={<Network />} />
        </Routes>
        {/* contact us */}
        <Routes>
          <Route exact path="/contactus" element={<Contactus />} />
        </Routes>
        <Routes>
          <Route exact path="/addperson" element={<AddPerson />} />
        </Routes>
        <Routes>
          <Route exact path="/sponsor" element={<PageCheck />} />
        </Routes>
        {/* about city */}

        <Routes>
          <Route exact path="/" element={<Home2 />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
