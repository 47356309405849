import React from "react";

function Subscribe() {
  return (
    <div>
      <section class="bg-white dark:bg-gray-900">
        <div class="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
          <div class="mx-auto max-w-screen-sm text-center">
            <h2 class="mb-4 text-4xl font-extrabold leading-tight text-gray-900 dark:text-white">
              Subscribe Our Website
            </h2>
            <p class="mb-6 font-light text-gray-500 dark:text-gray-400 md:text-lg">
              Engage our blog and news post with{" "}
              <span className="text-rose-900 font-bold">ገብርኄር </span> ፕሮጀክት
            </p>

            <input
              class="text-gray-700 border border-rose-900   font-small rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 "
              placeholder="email"
              type="email"
            ></input>

            <a
              href="/"
              class=" text-white bg-rose-900 hover:bg-rose-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
            >
              Submit
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Subscribe;
