import React, { useState } from "react";
import Details from "./Details";

function Yilma() {
  const [details, setDetails] = useState(false);
  return (
    <div>
      <section class="bg-white dark:bg-gray-900">
        <div class="container flex flex-col items-center px-4 py-12 mx-auto xl:flex-row">
          <div class="flex justify-center xl:w-1/2">
            <img
              class="h-80 w-80 sm:w-[28rem] sm:h-[28rem] flex-shrink-0 object-cover rounded-full"
              src="./image/1 png.png"
              alt=""
            />
          </div>

          <div class="flex flex-col items-center mt-6 xl:items-start xl:w-1/2 xl:mt-0">
            <h2 class="text-3xl font-bold tracking-tight text-gray-800 xl:text-4xl dark:text-white">
              Organization Overview:
            </h2>

            <p class="block max-w-2xl mt-4 text-xl text-gray-500 dark:text-gray-300">
              Gebr-Heir is a Ge'ez word which means "a good servant who profits
              from the little gift he was given.” The project was created with
              the goal of "boosting the economic capacity of Orthodox youth".
            </p>

            <div class="mt-6 sm:-mx-2">
              <div class="inline-flex w-full overflow-hidden rounded-lg shadow sm:w-auto sm:mx-2">
                <button
                  class="inline-flex items-center justify-center w-full px-5 py-3 text-base font-medium text-white bg-gradient-to-r from-rose-700 to-rose-900 hover:from-rose-600 hover:to-rose-600 sm:w-auto hover:shadow-lg "
                  onClick={() => {
                    setDetails((prev) => !prev);
                  }}
                >
                  Read more
                </button>
              </div>
            </div>
            <div>
              <Details details={details} setDetails={setDetails} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Yilma;
