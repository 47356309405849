import React, { useState } from "react";
import Navbarss from "../navbars/Navbarss";
import { FaDonate } from "react-icons/fa";
import { SiGithubsponsors } from "react-icons/si";
import { BsFillPersonCheckFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
function JoinUs() {
  const [drop, setDrop] = useState(false);
  const navigate = useNavigate();
  // function member() {
  //   navigate("/addperson");

  // }

  function sponsor() {
    navigate("/sponsor");
  }
  return (
    <div>
      <Navbarss />

      <section class="  pt-24">
        <div class=" lg:col-span-7 flex justify-center items-center mt-6">
          <p class="max-w-2xl mb-4 text-2xl font-extrabold leading-none md:text-2xl xl:text-2xl dark:text-white ">
            <a href="/join" className="text-rose-800 font-normal text-center">
              Support Our Project
            </a>
          </p>
        </div>
        <div class="container mx-auto px-4 mb-6">
          <div class="flex flex-wrap">
            <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
              <div class="max-w-md bg-white rounded-xl overflow-hidden cursor-pointer shadow-lg hover:shadow-2xl hover:scale-105 transform transition-all duration-500 p-2">
                <div class="px-4 py-5 flex-auto">
                  <div class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                    <FaDonate className="w-8 h-8" />
                  </div>
                  <h6 class="text-xl font-semibold">Donation</h6>
                  <p class="mt-2 mb-4 text-blueGray-500">
                    Do you know that your one-time donation makes an impact on
                    the economic capacity of orthodox Christian youths and their
                    social acceptance? Here, Gebr-Heir creates an opportunity to
                    make an impact on the lives of others through your donation.
                    Donate now and be a part of this blessing call.
                  </p>
                  <div class="mt-6 sm:-mx-2 px-20 ">
                    <div className="w-full flex flex-col mb-3">
                      <button
                        className="inline-flex items-center rounded-lg  justify-center w-full px-5 py-2 text-base font-medium text-white bg-gradient-to-r from-rose-700 to-rose-900 hover:from-rose-800 hover:to-rose-700 sm:w-auto"
                        type="button"
                        onClick={() => setDrop((prev) => !prev)}
                      >
                        {/* <FcDonate class=" mr-4  w-4 h-4 " /> */}
                        Donate Now
                      </button>
                      <div
                        className={`${
                          !drop && "hidden"
                        } w-44 z-40  top-o right-[60%] bg-gray-50 shadow-md rounded divide-y divide-gray-100 block `}
                      >
                        <ul
                          className="py-1 text-sm   mb-2 "
                          aria-labelledby="avatarButton"
                        >
                          <li>
                            <a
                              href="https://chapa.link/donation/view/DN-79wmQnyn9Yyd"
                              className="block py-2 px-4 hover:bg-rose-800 hover:rounded-lg  hover:text-white cursor-pointer"
                              onClick={() => {
                                setDrop((prev) => (prev = false));
                              }}
                            >
                              ETB
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://chapa.link/donation/view/DN-hJIkEEiFTAKG"
                              className="block py-2 px-4 hover:bg-rose-800 hover:rounded-lg  hover:text-white cursor-pointer"
                              onClick={() => {
                                setDrop((prev) => (prev = false));
                              }}
                            >
                              USD
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
              <div class="max-w-md bg-white rounded-xl overflow-hidden cursor-pointer shadow-lg hover:shadow-2xl hover:scale-105 transform transition-all duration-500 p-2">
                <div class="px-4 py-5 flex-auto">
                  <div class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-rose-400">
                    <BsFillPersonCheckFill className="w-8 h-8" />
                  </div>
                  <h6 class="text-xl font-semibold">MemberShip</h6>
                  <p class="mt-2 mb-4 text-blueGray-500">
                    Gebr-Heir project is not only ours, it is yours too. Why
                    don’t be our member and family with your regular
                    contributions and support? It is too simple to start a
                    journey with us. Click Billow and register as a member.
                    Gebr-Heir is happy about your presence with us as a family.
                  </p>
                  <div class="mt-6 sm:-mx-2">
                    <div class="inline-flex w-full overflow-hidden rounded-lg shadow sm:w-auto sm:mx-2">
                      <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSfNMsQ6vI0OJJD8uD9PGiMFIJ1hV3-zVHtTNZ_Ni6BMWzFDBA/viewform?usp=sf_link"
                        class="inline-flex items-center justify-center w-full px-5 py-2 text-base font-medium text-white bg-gradient-to-r from-rose-700 to-rose-900 hover:from-rose-800 hover:to-rose-700 sm:w-auto"
                        title=" _blank"
                        // onClick={member}
                      >
                        <span class="mx-2">Join Us</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center ">
              <div class="max-w-md bg-white rounded-xl overflow-hidden cursor-pointer shadow-lg hover:shadow-2xl hover:scale-105 transform transition-all duration-500 p-2">
                <div class="px-4 py-5 flex-auto">
                  <div class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                    <SiGithubsponsors className="w-8 h-8" />
                  </div>
                  <h6 class="text-xl font-semibold">Sponsorship</h6>
                  <p class="mt-2 mb-4 text-blueGray-500">
                    Gebr-Heir offers several packages of sponsorship to you to
                    support the project. Let you share the burden, and change to
                    the opportunity of being a reason for the socio-economic
                    improvement of at least one orthodox youth by covering the
                    expenses fully or partially.
                  </p>
                  <div class="mt-6 sm:-mx-2">
                    <div class="inline-flex w-full overflow-hidden rounded-lg shadow sm:w-auto sm:mx-2">
                      <button
                        class="inline-flex items-center justify-center w-full px-5 py-2 text-base font-medium text-white bg-gradient-to-r from-rose-700 to-rose-900 hover:from-rose-800 hover:to-rose-700 sm:w-auto"
                        onClick={sponsor}
                      >
                        <span class="mx-2">Read More</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default JoinUs;
