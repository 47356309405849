import React, { useState } from "react";

type InputFieldProps = {
  fieldLabel: string;
  value: string;
  setValue: (value: string) => void;
  required?: boolean;
  isEmail?: boolean;
  isPhone?: boolean;
  pattern?: string;
};

const InputField = ({
  fieldLabel,
  value,
  setValue,
  required = false,
  isEmail = false,
  isPhone = false,
  pattern = "",
}: InputFieldProps) => {
  const [isValid, setIsValid] = useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setValue(newValue);
    if (required) {
      setIsValid(newValue.trim().length > 0);
    } else {
      setIsValid(true);
    }
    if (isEmail) {
      setIsValid(/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newValue));
    }
    if (isPhone) {
      setIsValid(/^(09|2519)\d{8}$/.test(newValue));
    }
    if (pattern) {
      setIsValid(new RegExp(pattern).test(newValue));
    }
  };

  return (
    <div className="mb-3 space-y-2 w-full text-xs">
      {fieldLabel && (
        <label className="block mb-2 text-sm font-small text-gray-700 dark:text-gray-300">
          {fieldLabel}{" "}
          {required && (
            <span title="required" className="text-red-300">
              *
            </span>
          )}
        </label>
      )}
      <input
        className={`w-full text-base px-4 py-2 border-b border-gray-100 focus:outline-none rounded-md ${
          isValid ? "border-gray-300" : "border-red-500"
        } focus:outline-none focus:border-purple-500 `}
        required={required}
        type="text"
        value={value}
        onChange={handleChange}
      />
      {!isValid && (
        <p className="text-red-500 text-xs">
          {isEmail
            ? "Please enter a valid email address."
            : isPhone
            ? "Please enter a valid phone number."
            : "Please enter a valid value."}
        </p>
      )}
    </div>
  );
};

export default InputField;
