import React, { useState } from "react";
import Navbarss from "../navbars/Navbarss";
import Footer from "../home/Footer";
import DetailsSecond from "./Details copy";

export const news = [
  {
    id: 1,
    date: "2023-10-11",
    imageUrl: "./image/office.jpg",
    title:
      "Together with supporters and families, Gebr-Heir commemorates the office's soft opening.",
    content:
      "The Gebr-Heir Humanitarian Association opened its first and new office, which was situated in Addis Abeba's Nifas Silk Lafto Sub city, on January 14, 2023. Visitors from local churches and Sunday schools attended this event and offered their congratulations to Gebr-Heir on the opening of its new office. For the occasion, the chairman of the Gebr-Heir board described the project and its future objectives.",
  },
  {
    id: 2,
    date: "2023-10-12",
    imageUrl: "./image/meeting.jpg",
    title: "Egziharya Yilma Hailu visited the Gebr-Heir office.",
    content:
      "The daughter of Liqe Mezemeran Yilma Hailu, a well-known gospel singer and honorary ambassador for Gebr-Heir, paid a visit to the organization's headquarters. She had a fruitful discussion on potential areas of collaboration and cooperative initiatives. The board recognized her attendance, dedication, and her father's gift of his thirteenth album to the project.",
  },
  {
    id: 3,
    date: "2023-10-13",
    imageUrl: "./image/ssua.jpg",
    title:
      "Gebr-Heir and the EOTC Sunday School Union establish a Memorandum of Understanding.",
    content:
      "In order to improve the spiritual services provided by youth who work in Sunday schools, Gebr-Heir Project and the Ethiopian Orthodox Tewahido Church Sunday School Union have signed a memorandum of understanding to work together on the economic growth and capacity building of these adolescents. They committed to working together to share resources and information that would help them achieve their shared objective during the ceremony that was conducted on December 14, 2022, in the Gebr-Heir headquarters.",
  },
  {
    id: 4,
    date: "2023-10-13",
    imageUrl: "./image/sign.jpg",
    title:
      "Gebr-Heir and Qine Consulting sign a Memorandum of Understanding to collaborate on youth capacity building.",
    content:
      "Gebr-Heir and Qine consultancy, a company that specializes in training, consultancy, and capacity building, have signed a memorandum of understanding to collaborate on youth capacity building. According to the MoU, Qine and Gebr-Heir will collaborate to offer training and consultation to the project's intended beneficiaries.",
  },
];

function News() {
  const [details1, setDetails1] = useState(false);
  const [details2, setDetails2] = useState(false);

  const [selectedNews, setSelectedNews] = useState();

  return (
    <div>
      <Navbarss />
      <section class="text-gray-600 body-font pt-36">
        <div class="container px-5 mx-auto max-w-7x1">
          <div class="flex flex-wrap w-full mb-4 p-4">
            <div class="w-full mb-6 lg:mb-0">
              <h1 class="sm:text-4xl text-5xl  font-bold title-font mb-2 text-gray-900">
                News
              </h1>
              <div class="h-1 w-20 bg-rose-600 rounded"></div>
            </div>
          </div>
          <div class="flex flex-wrap -m-4 gap-5 h-fit">
            {news.map((singleNews) => {
              return (
                <NewsItem
                  key={singleNews.id}
                  showDetail={details1}
                  setShowDetail={setDetails1}
                  setSelectedNews={setSelectedNews}
                  {...singleNews}
                />
              );
            })}
            {selectedNews && (
              <DetailsSecond
                details2={details1}
                setDetails2={setDetails1}
                id={selectedNews}
              />
            )}
          </div>
        </div>
      </section>
      <div className="mt-10">
        <Footer />
      </div>
    </div>
  );
}

export default News;

const NewsItem = (props) => {
  const {
    title,
    content,
    imageUrl,
    createdDate,
    setShowDetail,
    showDetail,
    setSelectedNews,
    id,
  } = props;

  return (
    <div class="xl:w-[31%] md:w-1/2 p-4 md:p-2 xl:p-4">
      <div class="max-w-md bg-white rounded-xl overflow-hidden cursor-pointer shadow-lg hover:shadow-2xl hover:scale-105 transform transition-all duration-500 p-4 md:p-6">
        <img
          class="h-48 sm:h-60 xs:h-64 md:h-72 lg:h-60 xl:h-56 rounded w-full object-cover object-center mb-4 sm:mb-6"
          src={imageUrl || "./image/meeting.jpg"}
          alt="Imag"
        />

        <h2 class="text-base sm:text-lg text-gray-900 font-medium title-font mb-2 sm:mb-4">
          {title}
        </h2>

        <div class="flex mt-4 sm:mt-6 justify-center">
          <div class="w-full sm:w-auto sm:mx-2">
            <button
              class="items-center justify-center w-full px-4 sm:px-5 py-2 text-sm sm:text-base font-medium text-white bg-gradient-to-r from-rose-700 to-rose-900 hover:from-rose-800 hover:to-rose-700 sm:w-auto"
              onClick={() => {
                setShowDetail((prev) => !prev);
                setSelectedNews(id);
              }}
            >
              <span class="mx-1 sm:mx-2">Read more</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
