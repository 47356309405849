/* eslint-disable no-undef */
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import "animate.css";
import { useReactToPrint } from "react-to-print";
import InputField from "./InputField";
import axios from "axios";
function AddPerson() {
  const [fname, setFname] = useState<any>();
  const [middlename, setMiddlename] = useState<any>();
  const [lastName, setLastName] = useState<any>();
  const [gender, setGender] = useState<any>();
  const [school, setSchool] = useState<any>();
  const [sector, setSector] = useState<any>();
  const [subCity, setSubCity] = useState<any>();
  const [houseNumber, setHouseNumber] = useState<any>();
  const [wereda, setWereda] = useState<any>();
  const [pobox, setPobox] = useState<any>();
  const [phone, setPhone] = useState<any>();
  const [email, setEmail] = useState<any>();
  const [contribution, setContribution] = useState<any>();
  const [typecont, setTypecont] = useState<any>();
  const [contrtime, setContrtime] = useState<any>("");
  const [registration, setRegistration] = useState<any>();
  const [msg, setMsg] = useState(false);

  // form reset
  const resetForm = () => {
    setFname("");
    setMiddlename("");
    setLastName("");
    setGender("");
    setSchool("");
    setSector("");
    setSubCity("");
    setHouseNumber("");
    setWereda("");
    setPobox("");
    setPhone("");
    setContribution("");
    setTypecont("");
    setContrtime("");
    setRegistration("");
  };

  const [donor, setDonor] = useState(false);
  const [money, setMoney] = useState(false);
  const [lists, setLists] = useState(false);
  const [result, setResult] = useState("Member");
  const navigate = useNavigate();
  // print issue
  const exports = useRef<any>();
  const change = useReactToPrint({
    content: () => exports.current,
    documentTitle: "user List",
    onAfterPrint: () => alert("Data Saved"),
  });

  const [photo, setPhoto] = useState<any>(null);

  function add() {
    navigate("/join");
  }
  const apiUrl = process.env.REACT_APP_API_URL;
  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    resetForm();
    const fetchData = (link: string) => {
      axios
        .post(`${apiUrl}/${link}`, {
          type: "person",
          registration_date: registration,
          profile: {
            firstName: fname,
            middleName: middlename,
            lastName: lastName,
            gender: gender,
            marriageStatus: 0,
            birthDate: "25/02/2015",
            phoneNumber: phone,
            email: email,
            password: "345678a",
            school_Level: school,
            work_sector: sector,
            location: {
              sub_city: subCity,
              woreda: wereda,
              house_number: houseNumber,
            },
          },
          ...(link === "donor/memeber" && {
            fee: 1000,
          }),
          ...(link === "donor" && {
            contribution_type: contribution,
            contribution_amount: typecont,
            contribution_time: contrtime,
          }),
        })

        .then((response: any) => {
          if (response?.status === 201) {
            handleProfileSubmit(response.data?.profile?.id, photo);
          }
        })
        .then((data) => {
          setMsg((prev) => !prev);
          // Handle the successful response here
        })
        .catch((error) => {
          if (error.message === "Failed to fetch") {
            console.error("Network error:", error);
            // Handle network error here
          } else if (error.message === "Connection refused") {
            console.error("Connection refused error:", error);
            // Handle connection refused error here
          } else if (error.message === "500") {
            console.error("Server error:", error);
            // Handle server error here
          } else if (error.message === "400") {
            console.error("Bad request error:", error);
            // Handle bad request error here
          } else if (error.message === "404") {
            console.error("Not found error:", error);
            // Handle not found error here
          } else {
            console.error("Unknown error:", error);
            // Handle other error messages here
          }
        });
    };
    if (result === "Donor") {
      return fetchData("donor");
    } else {
      console.log("else block");
      return fetchData("donor/memeber");
    }
  };

  const handleProfileSubmit = async (id: any, profile: string | Blob) => {
    if (!profile) {
      return;
    }

    const formData = new FormData();
    formData.append("profilePicture", photo);

    try {
      const headers = { "Content-Type": "multipart/form-data" };
      const response = await axios.post(
        `${apiUrl}/profile/${id}/profile-picture`,
        formData,
        { headers }
      );
      return response;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="px-8 pt-8">
      <div className="mt-10 sm:mt-0">
        {/* title and back to list button */}
        <div className="md:col-span-1 mb-2">
          <div className="px-4 sm:px-0 mb-4 flex ">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Member Registration
            </h3>
            <button
              className="ml-auto justify-center rounded-md border py-2 px-4 text-sm font-small text-slate-500  shadow-lg border-slate-400 hover:bg-rose-800 hover:text-white focus:outline-none  focus:ring-offset-2 mb-4 "
              onClick={add}
            >
              Back to site
            </button>
          </div>
        </div>
        <div className="w-fit flex  px-8 pt-8">
          <div className="md:grid md:grid-cols-1 md:gap-6">
            <div className="mt-5 md:col-span-2 md:mt-0">
              <div className="overflow-hidden shadow sm:rounded-md">
                <div className="bg-white shadow px-4 py-5 sm:p-6">
                  <div className="grid  gap-8 grid-cols-1">
                    <div className="flex flex-col ">
                      <div className="mt-5">
                        <div className="form ">
                          {/* personal Add Form */}
                          <form onSubmit={handleSubmit}>
                            <div className=" bg-white shadow px-4 py-5 sm:p-6 overflow-hidden sm:rounded-md">
                              <div ref={exports} style={{ width: "100%" }}>
                                <div className="md:flex flex-row md:space-x-4 w-full text-xs ">
                                  <div className="w-full flex flex-col mb-6">
                                    <h1 className="block mb-2 font-bold text-xl text-gray-700 dark:text-gray-300">
                                      Member Add Form
                                    </h1>
                                  </div>
                                </div>

                                <div className="md:flex flex-row md:space-x-4 w-full text-xs ">
                                  <InputField
                                    value={fname}
                                    setValue={setFname}
                                    fieldLabel="First Name"
                                    required
                                  />
                                  <InputField
                                    value={middlename}
                                    setValue={setMiddlename}
                                    fieldLabel="MIddle Name"
                                    required
                                  />
                                  <InputField
                                    value={lastName}
                                    setValue={setLastName}
                                    fieldLabel="Last Name"
                                    required
                                  />
                                </div>
                                <div className="md:flex md:flex-row md:space-x-4 w-full text-xs mt-8">
                                  <div className="w-full flex flex-col mb-3">
                                    <label className="block mb-2 text-sm font-small text-gray-700 dark:text-gray-300">
                                      Gender
                                    </label>
                                    <select
                                      className=" w-full text-base px-4 py-2 border-b border-gray-300 focus:outline-none rounded-md focus:border-indigo-500 "
                                      required
                                      value={gender}
                                      onChange={(e) =>
                                        setGender(e.target.value)
                                      }
                                      name="gender"
                                    >
                                      <option value=""></option>
                                      <option value="0">Male</option>
                                      <option value="1">Female</option>
                                    </select>
                                  </div>
                                  <div className="w-full flex flex-col mb-3">
                                    <label className="block mb-2 text-sm font-small text-gray-700 dark:text-gray-300">
                                      School Level
                                    </label>
                                    <select
                                      className=" w-full text-base px-4 py-2 border-b border-gray-300 focus:outline-none rounded-md focus:border-indigo-500 "
                                      required
                                      value={school}
                                      onChange={(e) =>
                                        setSchool(e.target.value)
                                      }
                                      name="school-level"
                                    >
                                      <option value="a"></option>
                                      <option value="1 -8">1 - 8</option>
                                      <option value="9 - 10">9 - 10</option>
                                      <option value="11 - 12">11 - 12</option>
                                      <option value="Dimplom">Dimplom</option>
                                      <option value="Degree">Degree</option>
                                      <option value="Master's">Master's</option>
                                      <option value="PHD">PHD</option>
                                    </select>
                                  </div>
                                  <div className="w-full flex flex-col mb-3">
                                    <label className="block mb-2 text-sm font-small text-gray-700 dark:text-gray-300">
                                      Sector of work
                                    </label>
                                    <input
                                      // placeholder="dawit"
                                      className=" w-full text-base px-4 py-2 border-b border-gray-300 focus:outline-none rounded-md focus:border-indigo-500 "
                                      required
                                      type="text"
                                      value={sector}
                                      onChange={(e) =>
                                        setSector(e.target.value)
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="md:flex flex-row md:space-x-4 w-full text-xs mt-8">
                                  <InputField
                                    required
                                    value={pobox}
                                    setValue={setPobox}
                                    fieldLabel="Address"
                                  />
                                  <InputField
                                    value={phone}
                                    setValue={setPhone}
                                    fieldLabel="Phone Number"
                                    required
                                  />
                                  <InputField
                                    value={email}
                                    setValue={setEmail}
                                    fieldLabel="Email"
                                    required
                                  />
                                </div>
                                <div className="md:flex flex-row md:space-x-4 w-full text-xs mt-8">
                                  <div className="w-full flex flex-col mb-3">
                                    <label className="block mb-2 text-sm font-small text-gray-700 dark:text-gray-300">
                                      Contribution Type
                                    </label>
                                    <button
                                      type="button"
                                      className=" w-full text-base px-4 py-2  border border-gray-300 focus:outline-none rounded-md focus:border-indigo-500 "
                                      onClick={() => setLists((prev) => !prev)}
                                    >
                                      {result ? result : "Contribution  Type"}{" "}
                                    </button>
                                    <ul
                                      className={`${
                                        !lists && "hidden"
                                      } py-2 space-y-2 `}
                                    >
                                      <li>
                                        <p
                                          className="p-2.5 w-full text-gray-700 text-sm  px-4 py-3  hover:bg-gray-200 border-gray-700 rounded-lg focus:outline-none focus:border-purple-400 cursor-pointer"
                                          onClick={() => {
                                            setResult(
                                              (prev) => (prev = "Money")
                                            );
                                            setDonor((prev) => (prev = false));
                                            setLists((prev) => !prev);
                                            return setMoney((prev) => !prev);
                                          }}
                                        >
                                          <span className="flex-1 ml-3 text-left whitespace-nowrap">
                                            Money
                                          </span>
                                        </p>
                                      </li>
                                      <li>
                                        <p
                                          className="p-2.5 w-full text-gray-700 text-sm  px-4 py-3  hover:bg-gray-200 border-gray-700 rounded-lg focus:outline-none focus:border-purple-400 cursor-pointer"
                                          onClick={() => {
                                            setResult(
                                              (prev) => (prev = "Profession")
                                            );
                                            setMoney((prev) => (prev = false));
                                            setLists((prev) => !prev);
                                            return setDonor((prev) => !prev);
                                          }}
                                        >
                                          <span className="flex-1 ml-3 text-left whitespace-nowrap">
                                            Profession
                                          </span>
                                        </p>
                                      </li>
                                    </ul>{" "}
                                  </div>

                                  <div
                                    className={`${
                                      !money && "hidden"
                                    } w-full flex flex-col mb-3  `}
                                  >
                                    <label className="block mb-2 text-sm font-small text-gray-700 dark:text-gray-300">
                                      Amount
                                    </label>

                                    <input
                                      className=" w-full text-base px-4 py-2 border-b border-gray-300 focus:outline-none rounded-md focus:border-indigo-500 "
                                      type="text"
                                      value={typecont}
                                      onChange={(e) =>
                                        setTypecont(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div
                                    className={`${
                                      !money && "hidden"
                                    } w-full flex flex-col mb-3  `}
                                  >
                                    <label className="block mb-2 text-sm font-small text-gray-700 dark:text-gray-300">
                                      Contribution Time
                                    </label>
                                    <select
                                      className=" w-full text-base px-4 py-2 border-b border-gray-300 focus:outline-none rounded-md focus:border-indigo-500 "
                                      value={contrtime}
                                      onChange={(e) => {
                                        setContrtime(e.target.value);
                                      }}
                                      name="contibution-time"
                                    >
                                      <option></option>
                                      <option value="monthly">monthly</option>
                                      <option value="Yearly">Yearly</option>
                                    </select>
                                  </div>
                                  <div
                                    className={`${
                                      !donor && "hidden"
                                    } w-full flex flex-col mb-3  `}
                                  >
                                    <label className="block mb-2 text-sm font-small text-gray-700 dark:text-gray-300">
                                      Profession
                                    </label>

                                    <input
                                      className=" w-full text-base px-4 py-2 border-b border-gray-300 focus:outline-none rounded-md focus:border-indigo-500 "
                                      type="text"
                                      value={typecont}
                                      onChange={(e) =>
                                        setTypecont(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div
                                    className={`${
                                      !donor && "hidden"
                                    } w-full flex flex-col mb-3  `}
                                  >
                                    <label className="block mb-2 text-sm font-small text-gray-700 dark:text-gray-300">
                                      Way of Contribution
                                    </label>
                                    <select
                                      className=" w-full text-base px-4 py-2 border-b border-gray-300 focus:outline-none rounded-md focus:border-indigo-500 "
                                      value={contribution}
                                      onChange={(e) =>
                                        setContribution(e.target.value)
                                      }
                                      name="contribution-type"
                                    >
                                      <option value=""></option>
                                      <option value="Training">Training</option>
                                      <option value="Mentorship">
                                        Mentorship
                                      </option>
                                      <option value="Counseling">
                                        Counseling
                                      </option>
                                      <option value="Consultancy">
                                        Consultancy
                                      </option>
                                      <option value="Consultancy">
                                        Facilitation
                                      </option>
                                      <option value="Consultancy">
                                        Promotion And Networking
                                      </option>
                                      <option value="Document preparation and review ">
                                        Document preparation and review
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                              <div className="mt-5 text-right md:space-x-3 md:block flex flex-col-reverse">
                                <div
                                  className={`${!msg && "hidden"} flex `}
                                  // value={msg}
                                  // onChange={(e) => setMsg(e.target.value)}
                                >
                                  {/* <Notify /> */}
                                </div>

                                <button
                                  className="mb-2 md:mb-0 bg-white px-5 py-2 text-sm shadow-sm font-medium tracking-wider border text-gray-600 rounded-full hover:shadow-lg hover:bg-gray-100"
                                  onClick={change}
                                >
                                  {" "}
                                  Print{" "}
                                </button>
                                <button
                                  className="mb-2 md:mb-0 bg-blue-400 px-5 py-2 text-sm shadow-sm font-medium tracking-wider text-white rounded-full hover:shadow-lg hover:bg-blue-500"
                                  type="submit"
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      {/* personal Add Form end*/}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddPerson;
