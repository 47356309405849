import axios from "axios";
import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
function CheckOutOrder({
  checkOut,
  setCheckOut,
  selectedPerson,
  setselectedPerson,
}) {
  const exports = useRef();

  // eslint-disable-next-line no-unused-vars
  const change = useReactToPrint({
    content: () => exports.current,
    documentTitle: "user List",
    onAfterPrint: () => alert("Data Saved"),
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post(`/pharmacy/newChekout`, {
        date: "20/02/2015",
        drug: selectedPerson,
      })
      .then(() => {
        console.log("posted");
      });
  };

  return (
    <div className={`${!checkOut && "hidden"} fixed inset-0 `}>
      <div className="flex items-center justify-center h-screen bg-black bg-opacity-25">
        <div class="p-8 rounded-xl bg-white shadow mt-24">
          <div ref={exports} style={{ width: "100%" }}>
            <div className="text-center">
              <div className=" flex justify-center mb-4 ">
                <img
                  src="./image/heranimed.png"
                  alt=""
                  className="h-8 w-auto"
                />
              </div>
              <p className="mt-2 text-sm font-bold text-gray-500">
                Send To Check Out
              </p>
            </div>

            {/* profile list  */}
          </div>

          <div className="flex justify-center">
            <form onSubmit={handleSubmit}>
              <button
                class="mt-4 text-white py-2 px-4 mr-4  rounded bg-gray-700 hover:bg-gray-800 shadow hover:shadow-lg font-medium transition transform hover:-translate-y-0.5"
                onClick={() => {
                  setCheckOut((prev) => !prev);
                }}
              >
                {" "}
                No
              </button>{" "}
              <button
                class="mt-4 text-white py-2 px-4  rounded bg-green-600 hover:bg-green-700 shadow hover:shadow-lg font-medium transition transform hover:-translate-y-0.5"
                // onClick={change}
                // onClick={() => {
                //   setCheckOut((prev) => !prev);
                // }}
                type="submit"
              >
                {" "}
                Yes
              </button>{" "}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckOutOrder;
