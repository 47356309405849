import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
// Default theme
import "@splidejs/react-splide/css";

// or other themes
import "@splidejs/react-splide/css/skyblue";
import "@splidejs/react-splide/css/sea-green";

// or only core styles
import "@splidejs/react-splide/css/core";

function Testimonial() {
  return (
    <div className="mb-8">
      {" "}
      <Splide>
        <SplideSlide>
          <section class=" dark:bg-gray-900">
            <div class="max-w-screen-xl px-4 py-4 mx-auto text-center lg:py-16 lg:px-6">
              <div class="text-center pb-12">
                <h1 class="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-rose-700">
                  Testimonials
                </h1>
              </div>
              <figure class="max-w-screen-md mx-auto">
                <svg
                  class="h-12 mx-auto mb-3 text-gray-700 dark:text-gray-600"
                  viewBox="0 0 24 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                    fill="currentColor"
                  />
                </svg>
                <blockquote>
                  <p class="text-2xl font-medium text-gray-900 dark:text-white">
                    "If we do not invest in the comprehensive development of
                    today's youth, we risk encountering a weakened church that
                    may struggle to achieve its objectives."
                  </p>
                </blockquote>
                <figcaption class="flex items-center justify-center mt-6 space-x-3">
                  <img
                    class="w-6 h-6 rounded-full"
                    src="./image/yilmas.jpg"
                    alt="profile pictures"
                  />
                  <div class="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                    <div class="pr-3 font-medium text-gray-900 dark:text-white">
                      Liqe Mezmeran Yilma hailu
                    </div>
                    <div class="pl-3 text-sm font-light text-gray-500 dark:text-gray-400">
                      Honorary Ambassador
                    </div>
                  </div>
                </figcaption>
              </figure>
            </div>
          </section>
        </SplideSlide>
        <SplideSlide>
          <section class=" dark:bg-gray-900">
            <div class="max-w-screen-xl px-4 py-4 mx-auto text-center lg:py-16 lg:px-6">
              <div class="text-center pb-12">
                <h1 class="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-rose-700">
                  Testimonials
                </h1>
              </div>
              <figure class="max-w-screen-md mx-auto">
                <svg
                  class="h-12 mx-auto mb-3 text-gray-700 dark:text-gray-600"
                  viewBox="0 0 24 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                    fill="currentColor"
                  />
                </svg>
                <blockquote>
                  <p class="text-2xl font-medium text-gray-900 dark:text-white">
                    "ሰው የተፈጠረው መልካምን ነገር ለመሥራት ነው ምንምኳን ገብርኄርን ሰው ይጀምረው እንጂ ሀሳቡ
                    የእግዚአብሔር ነው"
                  </p>
                </blockquote>
                <figcaption class="flex items-center justify-center mt-6 space-x-3">
                  <img
                    class="w-6 h-6 rounded-full"
                    src="./image/Picts for Galary/Graduation Day 4.jpg"
                    alt="profile pictures"
                  />
                  <div class="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                    <div class="pr-3 font-medium text-gray-900 dark:text-white">
                      መጋቤ ሀዲስ በየነ ለማ
                    </div>
                    <div class="pl-3 text-sm font-light text-gray-500 dark:text-gray-400">
                      በኢ/ኦ/ተ/ቤ/ክ የአዲስ አበባ ሀገረስብከት የምግባረ ሰናይ ክፍል ሀላፊ
                    </div>
                  </div>
                </figcaption>
              </figure>
            </div>
          </section>
        </SplideSlide>
      </Splide>
    </div>
  );
}

export default Testimonial;
