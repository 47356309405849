import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
// Default theme
import "@splidejs/react-splide/css";

// or other themes
import "@splidejs/react-splide/css/skyblue";
import "@splidejs/react-splide/css/sea-green";

// or only core styles
import "@splidejs/react-splide/css/core";
function Partners() {
  return (
    <div>
      <section class="bg-white ">
        <div class="py-8  lg:py-16 mx-auto max-w-screen-xl px-4">
          <h2 class="mb-8 lg:mb-16 text-3xl font-extrabold tracking-tight leading-tight text-center text-rose-700 dark:text-white md:text-4xl">
            Partner Company's
          </h2>
          <Splide>
            <SplideSlide>
              <div class=" ml-24 grid grid-cols-2 gap-8 text-gray-500 sm:gap-12 md:grid-cols-3 lg:grid-cols-6 ">
                <a
                  href="https://www.addisababa.eotc.org.et/"
                  class="flex justify-center items-center"
                >
                  <img
                    src="./image/aaor.png"
                    alt="telebirr"
                    class="h-50 text-rose-900 hover:text-rose-800  w-50"
                  />
                </a>
                <a href="/" class="flex justify-center items-center">
                  <img
                    src="./image/FINAL LOGO-01.png"
                    alt="cbe"
                    class="h-50 w-50 text-rose-900 hover:text-rose-800 "
                  />
                </a>

                <a
                  href="https://www.ahadubank.com/"
                  target="_blank "
                  class="flex justify-center items-center"
                >
                  <img
                    src="./image/ahadu.png"
                    alt="apollo"
                    class="h-50 text-rose-900 hover:text-rose-800  w-50"
                  />
                </a>

                <a
                  href="https://shegersacco.com/"
                  class="flex justify-center items-center"
                  target="_blank "
                >
                  <img
                    src="./image/logo-Sheger.png"
                    alt="sheger"
                    class="h-90 w-90 text-rose-900 hover:text-rose-800 "
                  />
                </a>
              </div>
            </SplideSlide>

            <SplideSlide>
              <div class=" ml-24 grid grid-cols-2 gap-8fd text-gray-500 sm:gap-12 md:grid-cols-3 lg:grid-cols-6 ">
                <a
                  href="https://eotc-gssu.org/a/"
                  class="flex justify-center items-center"
                >
                  <img
                    src="./image/ssu copy.png"
                    alt="telebirr"
                    class="h-50 text-rose-900 hover:text-rose-800  w-50"
                  />
                </a>
                <a
                  href="https://yotorscc.com/"
                  class="flex justify-center items-center"
                >
                  <img
                    src="./image/logo Yotor.WEBP"
                    alt="yotor"
                    class="h-90 w-90 text-rose-900 hover:text-rose-800 "
                  />
                </a>

                <a
                  href="https://www.ahadubank.com/"
                  class="flex justify-center items-center"
                  target="_blank "
                >
                  <img
                    src="./image/landscape.png"
                    alt="herani"
                    class="h-90 w-90 text-rose-900 hover:text-rose-800 "
                  />
                </a>
                <a href="/" class="flex justify-center items-center">
                  <img
                    src="./image/qine.png"
                    alt="zemen"
                    class="h-90 w-90 text-rose-900 hover:text-rose-800 "
                  />
                </a>
              </div>
            </SplideSlide>
          </Splide>
        </div>
      </section>
    </div>
  );
}

export default Partners;
