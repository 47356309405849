import React from "react";
import "animate.css";
function Struc() {
  return (
    <div>
      <div class="container">
        <div class="flex flex-col md:grid grid-cols-9 mx-auto p-2 text-blue-50">
          <div class="flex flex-row-reverse md:contents ">
            <div class="bg-rose-900 col-start-1 col-end-5 p-4 rounded-xl my-4 ml-auto shadow-md animate__animated animate__bounceInDown animate__delay-3s ">
              <h3 class="font-semibold text-lg mb-1">Our Short History</h3>
              <p class="leading-tight text-justify mb-3">
                The Gebre Hier non-profit was established on October 11, 2022
                G.C. Some concerned members of the church came together
                voluntarily to create a project with the goal of "boosting the
                economic capacity of Orthodox youth servants." Its five founding
                and board members are members of Lafto Debre Tiguhan St. Michael
                Sunday School. Shortly after its establishment, Gebre Hier was
                recognized by the EOTC’s Addis Ababa Archdiocese Charitable Acts
                Department on November 1, 2022.
              </p>
            </div>
            <div class="col-start-5 col-end-6 md:mx-auto relative mr-10">
              <div class="h-full w-6 flex items-center justify-center">
                <div class="h-full w-1 bg-rose-900 pointer-events-none  "></div>
              </div>
              <div class="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-rose-900  shadow animate__animated animate__heartBeat  animate__infinite"></div>
            </div>
          </div>

          <div class="flex md:contents ">
            <div class="col-start-5 col-end-6 mr-10 md:mx-auto relative ">
              <div class="h-full w-6 flex items-center justify-center">
                <div class="h-full w-1 bg-rose-900  pointer-events-none"></div>
              </div>
              <div class="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-rose-900  shadow animate__animated animate__heartBeat  animate__infinite"></div>
            </div>
            <div class="bg-rose-900  col-start-6 col-end-10 p-4 rounded-xl my-4 mr-auto shadow-md animate__animated animate__bounceInRight animate__delay-3s">
              <h3 class="font-semibold text-lg mb-1 ">Our Mission</h3>
              <p class="leading-tight text-justify">
                Gebr-Heir provides a variety of programs to assist orthodox
                youth grow in their profession and career, as well as establish
                a robust digital business network.
              </p>
            </div>
          </div>

          <div class="flex flex-row-reverse md:contents">
            <div class="bg-rose-900  col-start-1 col-end-5 p-4 rounded-xl my-4 ml-auto shadow-md animate__animated animate__fadeInTopRight animate__delay-2s">
              <h3 class="font-semibold text-lg mb-1">Our Vision</h3>
              <p class="leading-tight text-justify">
                We envision that Gebr-Heir will support the development of
                financially stable Orthodox youth who can actively engage in the
                nation's economic, political, and social concerns.
              </p>
            </div>
            <div class="col-start-5 col-end-6 md:mx-auto relative mr-10">
              <div class="h-full w-6 flex items-center justify-center">
                <div class="h-full w-1 bg-rose-900  pointer-events-none"></div>
              </div>
              <div class="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-rose-900  shadow animate__animated animate__heartBeat  animate__infinite"></div>
            </div>
          </div>

          <div class="flex md:contents">
            <div class="col-start-5 col-end-6 mr-10 md:mx-auto relative">
              <div class="h-full w-6 flex items-center justify-center">
                <div class="h-full w-1 bg-rose-900  pointer-events-none"></div>
              </div>
              <div class="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-rose-900  shadow animate__animated animate__heartBeat  animate__infinite"></div>
            </div>
            <div class="bg-rose-900  col-start-6 col-end-10 p-4 rounded-xl my-4 mr-auto shadow-md">
              <h3 class="font-semibold text-lg mb-1">Values and Principles</h3>
              <p class="leading-tight text-justify">
                • Spiritual service <br></br>• Strong work culture<br></br> •
                Brotherhood
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Struc;
