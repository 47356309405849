import React from "react";

import Navbarss from "../navbars/Navbarss";
import "animate.css";
import Partners from "../About/Partners";
import Land from "./Land";
import Yilma from "./Yilma";
import Subscribe from "./Subscribe";
import OfficeEnv from "./OfficeEnv";
import WhatWeDo from "./WhatWeDo";
import HeroPage from "./HeroPage";
import Footer from "./Footer";
import EventSchedul from "../About/EventSchedul";
function Home2() {
  return (
    <div>
      <Navbarss />
      <div>
        <HeroPage />
      </div>
      <div>
        <Land />
      </div>
      {/* four target points */}
      <div>
        <WhatWeDo />
      </div>
      {/* our work place and employee */}
      <div>
        <OfficeEnv />
      </div>
      <div>
        <Yilma />
      </div>
      <div>
        <EventSchedul />
      </div>
      {/* partner's */}
      <div>
        <Partners />
      </div>

      {/* subscribe page */}
      <div>
        <Subscribe />
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Home2;
