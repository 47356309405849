import React from "react";
import { SplideSlide } from "@splidejs/react-splide";
import { TbTargetArrow } from "react-icons/tb";
import { GrAchievement } from "react-icons/gr";
import { BiTask } from "react-icons/bi";
import Navbarss from "../navbars/Navbarss";

function Seeding() {
  return (
    <div>
      <Navbarss />

      <SplideSlide>
        <div class="relative w-full">
          <span
            id="blackOverlay"
            class="w-full h-full absolute opacity-50 bg-black"
          ></span>
          <img src="./image/bak.jpg" alt="telebirr" class="h-100 w-full " />
          <div class="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center text-gray-500 sm:gap-12 dark:text-gray-400">
            <h1 class="font-extrabold text-white text-3xl md:text-4xl lg:text-5xl font-heading shadow-2xl  ">
              Seed Funding
            </h1>
            <p className="p-8 text-white text-center">
              {" "}
              This initiative will assist Orthodox youth who exhibit
              entrepreneurial aptitude, <br></br>opportunity, and commitment but
              lack the necessary resources to realize their ideas.
            </p>
          </div>
        </div>
      </SplideSlide>

      <section class=" dark:bg-gray-900">
        <div class="max-w-screen-xl px-4 py-4 mx-auto text-center lg:py-16 lg:px-6">
          <div class="text-center pb-12 rel">
            <h1 class="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-rose-700">
              Challenge
            </h1>
          </div>
          <figure class="max-w-screen-md mx-auto">
            <p class="text-md  text-gray-900 dark:text-white ">
              Access to seed funding is a lifeline to young Ethiopian
              innovators. Many are forced to stay behind with their innovative
              ideas due to lack of access to capital, lack of entrepreneurial
              training, and other economic factors. Gebr-Heir created the seed
              funding program seeking to transform such innovators into
              entrepreneurs - entrepreneurs that contribute a net positive to
              the local economy, entrepreneurs that create jobs for the local
              community, entrepreneurs that inspire and fully support the next
              generation of innovators.
            </p>
            <p className="my-4">
              Our seed funding program is not just about providing starting
              capital; it is rather about creating holistic entrepreneurs.
              Through this program, youth innovators are provided opportunities
              to network with seasoned business professionals and fellow
              entrepreneurs.
            </p>
          </figure>
        </div>
      </section>
      <section class="  pt-8">
        <div class=" lg:col-span-7 flex justify-center items-center mt-6">
          <p class="max-w-2xl mb-4 text-2xl font-extrabold leading-none md:text-2xl xl:text-2xl dark:text-white ">
            <a href="/join" className="text-rose-800 font-normal text-center">
              Support Our Project
            </a>
          </p>
        </div>
        <div class="container mx-auto px-4 mb-6">
          <div class="flex flex-wrap">
            <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
              <div class="max-w-md bg-white rounded-xl overflow-hidden cursor-pointer shadow-lg hover:shadow-2xl hover:scale-105 transform transition-all duration-500 p-2">
                <div class="px-4 py-5 flex-auto">
                  <div class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                    <BiTask className="w-8 h-8" />
                  </div>
                  <h6 class="text-xl font-semibold">What we do</h6>
                  <p class="mt-2 mb-4 text-blueGray-500">
                    The major function of the Gebr-Hair project is to provide
                    finance to newly emerging orthodox youth entrepreneurs and
                    serve as a conduit between them and SSACOs.
                  </p>
                </div>
              </div>
            </div>
            <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
              <div class="max-w-md bg-white rounded-xl overflow-hidden cursor-pointer shadow-lg hover:shadow-2xl hover:scale-105 transform transition-all duration-500 p-2">
                <div class="px-4 py-5 flex-auto">
                  <div class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-rose-400">
                    <GrAchievement className="w-8 h-8 " />
                  </div>
                  <h6 class="text-xl font-semibold">Achievement </h6>
                  <p class="mt-2 mb-16 text-blueGray-500">
                    11 Orthodox youth have received support and startup money
                    over the past seven months to launch their businesses.
                  </p>
                </div>
              </div>
            </div>
            <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center ">
              <div class="max-w-md bg-white rounded-xl overflow-hidden cursor-pointer shadow-lg hover:shadow-2xl hover:scale-105 transform transition-all duration-500 p-2">
                <div class="px-4 py-5 flex-auto">
                  <div class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                    <TbTargetArrow className="w-8 h-8" />
                  </div>
                  <h6 class="text-xl font-semibold">2023/24 target</h6>
                  <p class="mt-2 mb-16 text-blueGray-500">
                    Funding, linking, and following up on over 130
                    entrepreneurs' business ideas is targeted for the years
                    2023–24.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Seeding;
