import React from "react";
import Navbarss from "../navbars/Navbarss";

function Gallery() {
  return (
    <div>
      <Navbarss />
      <div class="container mx-auto p-4 pt-36">
        <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
          <div class="grid gap-4">
            <div className="group relative">
              <img
                class="h-auto max-w-full rounded-lg"
                src="./image/Picts for Galary/Visit from Egziharia Yilma.jpg"
                alt=""
              />
              <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-black bg-opacity-75 transition-opacity text-white text-center flex items-center justify-center">
                Visit from Egziharia Yilma
              </div>
            </div>
            <div className="group relative">
              <img
                class="h-auto max-w-full rounded-lg"
                src="./image/Picts for Galary/Meeting with Egziharia Yilma.jpg"
                alt=""
              />
              <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-black bg-opacity-75 transition-opacity text-white text-center flex items-center justify-center">
                Meeting with Egziharia Yilma
              </div>
            </div>
            <div className="group relative">
              <img
                class="h-auto max-w-full rounded-lg"
                src="./image/Picts for Galary/Soft opening of Gebr-heir.jpg"
                alt=""
              />
              <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-black bg-opacity-75 transition-opacity text-white text-center flex items-center justify-center">
                Soft opening of Gebr-heir
              </div>
            </div>
          </div>
          <div class="grid gap-4">
            <div className="group relative">
              <img
                class="h-auto max-w-full rounded-lg"
                src="./image/Picts for Galary/Graduation Day 2.jpg"
                alt=""
              />
              <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-black bg-opacity-75 transition-opacity text-white text-center flex items-center justify-center">
                Graduation Day
              </div>
            </div>
            <div className="group relative">
              <img
                class="h-auto max-w-full rounded-lg"
                src="./image/Picts for Galary/Graduation Day 4.jpg"
                alt=""
              />
              <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-black bg-opacity-75 transition-opacity text-white text-center flex items-center justify-center">
                Graduation Day
              </div>
            </div>
            <div className="group relative">
              <img
                class="h-auto max-w-full rounded-lg"
                src="./image/Picts for Galary/Graduation Day 3.jpg"
                alt=""
              />
              <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-black bg-opacity-75 transition-opacity text-white text-center flex items-center justify-center">
                Graduation Day
              </div>
            </div>
          </div>
          <div class="grid gap-4">
            <div className="group relative">
              <img
                class="h-auto max-w-full rounded-lg"
                src="./image/Picts for Galary/Signing MOU with Qine 3.jpg"
                alt=""
              />
              <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-black bg-opacity-75 transition-opacity text-white text-center flex items-center justify-center">
                Signing MOU with Qine
              </div>
            </div>
            <div className="group relative">
              <img
                class="h-auto max-w-full rounded-lg"
                src="./image/Picts for Galary/Mass Training.jpg"
                alt=""
              />
              <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-black bg-opacity-75 transition-opacity text-white text-center flex items-center justify-center">
                Mass Training
              </div>
            </div>
            <div className="group relative">
              <img
                class="h-auto max-w-full rounded-lg"
                src="./image/Picts for Galary/Vocational Training 2.jpg"
                alt=""
              />
              <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-black bg-opacity-75 transition-opacity text-white text-center flex items-center justify-center">
                Vocational Training
              </div>
            </div>
          </div>
          <div class="grid gap-4">
            <div className="group relative">
              <img
                class="h-auto max-w-full rounded-lg"
                src="./image/Picts for Galary/Vocational Training.jpg"
                alt=""
              />
              <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-black bg-opacity-75 transition-opacity text-white text-center flex items-center justify-center">
                Vocational Training
              </div>
            </div>
            <div className="group relative">
              <img
                class="h-auto max-w-full rounded-lg"
                src="./image/Picts for Galary/Vocational Training4.jpg"
                alt=""
              />
              <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-black bg-opacity-75 transition-opacity text-white text-center flex items-center justify-center">
                Vocational Training
              </div>
            </div>
            <div className="group relative">
              <img
                class="h-auto max-w-full rounded-lg"
                src="./image/Picts for Galary/Signing MOU with Addis Ababa SSU.jpg"
                alt=""
              />
              <div className="absolute inset-0 opacity-0 group-hover:opacity-100 bg-black bg-opacity-75 transition-opacity text-white text-center flex items-center justify-center">
                Signing MOU with Addis Ababa SSU
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
