import React from "react";
import { useNavigate } from "react-router-dom";

function Land() {
  const navigate = useNavigate();

  function about() {
    navigate("/about");
  }
  return (
    <div>
      <section class="relative  bg-blueGray-50">
        <div class="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen-75">
          <div
            class="absolute top-0 w-full h-full bg-center bg-cover "
            style={{
              "background-image":
                "url('https://previews.123rf.com/images/derejeb/derejeb1501/derejeb150100217/35780550-ethiopian-orthodox-followers-celebrate-timket-the-ethiopian-orthodox-celebration-of-epiphany-on-janu.jpg')",
              "object-fit": "cover",
            }}
          >
            <span
              id="blackOverlay"
              class="w-full h-full absolute opacity-75 bg-black"
            ></span>
          </div>
          <div class="container relative mx-auto">
            <div class="items-center flex flex-wrap">
              <div class="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <div class="pr-12">
                  <h1 class="text-white font-semibold text-5xl">
                    Our Short History
                  </h1>
                  <p class="mt-4 text-lg  text-gray-200">
                    The Gebr-Heir non-profit was established on October 11, 2022
                    G.C. Some concerned members of the church came together
                    voluntarily to create a project with the goal of "boosting
                    the economic capacity of Orthodox youth servants." Its five
                    founding and board members are members of Lafto Debre
                    Tiguhan St. Michael Sunday School. Shortly after its
                    establishment, Gebre Hier was recognized by the EOTC’s Addis
                    Ababa Archdiocese Charitable Acts Department on November 1,
                    2022.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
            style={{ transform: "translateZ(0px)" }}
          >
            <svg
              class="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                class="text-blueGray-200 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </div>
        <section class="pb-10 bg-blueGray-200 -mt-24">
          <div class="container mx-auto px-4">
            <div class="flex flex-wrap">
              <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div class="px-4 py-5 flex-auto">
                    <div class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                      <i class="fas fa-award"></i>
                    </div>
                    <h6 class="text-xl font-semibold">Our Mission</h6>
                    <p class="mt-2 mb-4 text-blueGray-500">
                      Gebr-Heir provides a variety of programs to assist
                      orthodox youth grow in their profession and career, as
                      well as establish a robust digital business network.
                    </p>
                  </div>
                </div>
              </div>
              <div class="w-full md:w-4/12 px-4 text-center">
                <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div class="px-4 py-5 flex-auto">
                    <div class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-lightBlue-400">
                      <i class="fas fa-retweet"></i>
                    </div>
                    <h6 class="text-xl font-semibold">Our Vision</h6>
                    <p class="mt-2 mb-4 text-blueGray-500">
                      We envision that Gebr-Heir will support the development of
                      financially stable Orthodox youth who can actively engage
                      in the nation's economic, political, and social concerns.
                    </p>
                  </div>
                </div>
              </div>
              <div class="pt-6 w-full md:w-4/12 px-4 text-center">
                <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div class="px-4 py-5 flex-auto">
                    <div class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-emerald-400">
                      <i class="fas fa-fingerprint"></i>
                    </div>
                    <h6 class="text-xl font-semibold">Values and Principles</h6>
                    <p class="mt-2 mb-4 text-blueGray-500">
                      <ol>
                        <li>Spiritual service</li>
                        <li> Strong work culture </li>
                        <li>Brotherhood</li>
                      </ol>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <footer class="relative mt-1 flex justify-center">
            <div class="text-sm text-blueGray-500 font-semibold py-1">
              <div class="mt-6 sm:-mx-2">
                <div class="inline-flex w-full overflow-hidden rounded-lg shadow sm:w-auto sm:mx-2">
                  <button
                    class="inline-flex items-center justify-center w-full px-5 py-3 text-base font-medium text-white bg-gradient-to-r from-rose-700 to-rose-900 hover:from-rose-600 hover:to-rose-600 sm:w-auto"
                    onClick={about}
                  >
                    <span class="mx-2">Read more</span>
                  </button>
                </div>
              </div>
            </div>
          </footer>
        </section>
      </section>
    </div>
  );
}

export default Land;
