import React from "react";

function OfficeEnv() {
  return (
    <div>
      <section class="bg-white dark:bg-gray-900">
        <div class="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
          <div class="font-light text-gray-500 sm:text-lg dark:text-gray-400">
            <h2 class="mb-4 text-4xl font-extrabold text-gray-900 dark:text-white">
              Achievement & Success
            </h2>
            <p class="mb-4">
              53 Sunday School young employees from 15 different Sunday schools
              in Addis have received career-specific vocational skill training
              since the project's beginning just six months ago.
            </p>
            <p>
              Moreover, 11 youth servants have been provided with startup
              funding and support to bring their business ideas to life.
            </p>
          </div>
          <div class="grid grid-cols-2 gap-4 mt-8">
            <img
              class="w-full rounded-lg "
              // src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/office-long-2.png"
              src="./image/leather.jpg"
              alt="office content 1"
            />

            <img
              class="mt-4 w-full lg:mt-10 rounded-lg"
              // src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/office-long-1.png"
              src="./image/dress.jpg"
              alt="office content 2"
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default OfficeEnv;
