import React from "react";
import Navbarss from "../navbars/Navbarss";
import Struc from "./Struc";
import Partners from "./Partners";
import Testimonial from "./Testimonial";
import Member from "./Member";
import EventSchedul from "./EventSchedul";
import Footer from "../home/Footer";
import Yilma from "../home/Yilma";

function About() {
  return (
    <div>
      <Navbarss />

      <div>
        <div className="pt-6">
          <Yilma />
        </div>
        <div>
          <Struc />
        </div>
        <div>
          <Member />
        </div>
        <div>
          <Testimonial />
        </div>

        <div>
          <Partners />
        </div>

        <EventSchedul />

        <div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default About;
