import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
function Details({ details, setDetails }) {
  const exports = useRef();

  // eslint-disable-next-line no-unused-vars
  const change = useReactToPrint({
    content: () => exports.current,
    documentTitle: "user List",
    onAfterPrint: () => alert("Data Saved"),
  });
  return (
    <div
      className={`${!details && "hidden"} fixed inset-0 overflow-scroll z-50`}
    >
      <div className="flex items-center justify-center h-screen bg-black bg-opacity-25">
        <div class="p-8 rounded-xl bg-white shadow mt-24">
          <div ref={exports} style={{ width: "100%" }}>
            {" "}
            <div class="grid grid-cols-1 md:grid-cols-3">
              {" "}
              <div class="grid grid-cols-3 text-center order-last md:order-first mt-20 md:mt-0 mb-4">
                {" "}
              </div>{" "}
              <div class="relative">
                {" "}
                <div class="w-44 h-44 bg-white mx-auto rounded-full shadow-2xl absolute inset-x-0 top-0 -mt-24 flex items-center justify-center text-green-500">
                  <img
                    src="./image/1 png.png"
                    class="h-34 w-34 rounded-full"
                    alt="profile"
                  />
                </div>{" "}
              </div>{" "}
            </div>{" "}
            <div class="mt-20 text-center border-b pb-12">
              {" "}
              <h1 class="text-4xl font-medium text-gray-700">
                Organization Overview:
              </h1>{" "}
              <p class="mt-8 text-gray-500">
                Gebr-Heir is a Ge'ez word which means "a good servant who
                profits from the little gift he was given.” The project was
                created with the goal of "boosting the economic capacity of
                Orthodox youth".
              </p>
              <p class="mt-8 text-gray-500">
                The EOTC actively engages its members through consistent service
                traditions and practical spirituality. Recently, the church has
                faced serious Challenges that put a constraint to its
                performance as a spiritual organization. The church’s members
                also have faced open discouragement from engaging with the
                church and its services. Gebr-Heir was founded in the midst of
                such times to help support youth members of the church continue
                engaging with the church regardless of financial and political
                inequity they deal with.
              </p>{" "}
              <p class="mt-2 text-gray-500 ">
                As the largest church in Ethiopia, the Ethiopian Orthodox
                Tewahedo Church sustains many members that live under the
                poverty line. The church’s host a significant number of youth
                servants. These youth, as members of the country’s larger
                population, face all sorts of Challenges that are caused by the
                country’s economic and political status. The most concerning of
                such Challenges is unemployment. The Gebr-Heir Project was
                established by a few concerned Ethiopian Orthodox Christians who
                came together voluntarily to create a project with the goal of
                "boosting the economic capacity of the Orthodox Church youths”.
              </p>{" "}
              <p class="mt-2 text-gray-500 ">
                Youth of the Ethipian Orthodox church share a set of common
                values and a special sense of unity that stems from the mother
                church. If supported in their union, we are certain that this
                group can bring about individual and societal accomplishments.
                Our organization, Gebr-heir, is seeking to empower this union in
                hopes of solving the church’s and the country’s bigger problems.
              </p>
            </div>{" "}
          </div>
          <div className="flex justify-center">
            <button
              class="mt-4 text-white py-2 px-4 mr-4  rounded bg-gray-700 hover:bg-gray-800 shadow hover:shadow-lg font-medium transition transform hover:-translate-y-0.5"
              onClick={() => {
                setDetails((prev) => !prev);
              }}
            >
              {" "}
              Back
            </button>{" "}
            <button
              class="mt-4 text-white py-2 px-4  rounded bg-rose-800 hover:bg-rose-700 shadow hover:shadow-lg font-medium transition transform hover:-translate-y-0.5"
              onClick={() => {
                setDetails((prev) => !prev);
              }}
            >
              {" "}
              Finish
            </button>{" "}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Details;
