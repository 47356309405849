import React, { useEffect } from "react";
import { ImQuotesLeft } from "react-icons/im";
import { Splide, SplideSlide } from "@splidejs/react-splide";
// Default theme
import "@splidejs/react-splide/css";

// or other themes
import "@splidejs/react-splide/css/skyblue";
import "@splidejs/react-splide/css/sea-green";

// or only core styles
import "@splidejs/react-splide/css/core";

import "@splidejs/splide/dist/css/themes/splide-default.min.css";

function HeroPage() {
  useEffect(() => {
    // No need to create a new Splide instance and use .mount().
    // The Splide component handles it internally.
  }, []);
  return (
    <div className="splide">
      <Splide
        options={{
          type: "loop", // Loop the slides.
          perPage: 1, // Number of slides to show at once.
          autoplay: true, // Enable auto-play.
          interval: 3000, // Set the interval between slides (in milliseconds).
        }}
      >
        <SplideSlide>
          <section class=" ">
            <div class="grid max-w-screen-xl px-4  py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
              <div class="mr-auto place-self-center lg:col-span-7">
                <h1 class="text-center max-w-2xl mb-4 text-4xl font-extrabold leading-none md:text-5xl xl:text-6xl ">
                  <span className="text-rose-800 font-extrabold">ገብር ኄር</span>{" "}
                  ፕሮጀክት
                </h1>
                <p class="max-w-2xl mb-6 font-extrabold text-gray-500 lg:mb-8 md:text-2xl lg:text-2xl text-center">
                  በእናንተ ደጋጎች እገዛ ለብዙዎች ጥቂት መክሊት እንሰጣለን ብዙ ቅን አትራፊ አገልጋዮችንም
                  እናፈራለን
                </p>
                <p class="max-w-2xl mb-6 font-extralight text-gray-500 lg:mb-8 md:text-md lg:text-md ">
                  <span className="flex justify-center mb-3 items-center">
                    {" "}
                    <ImQuotesLeft className="" />{" "}
                  </span>
                  <p className=" flex justify-center text-center">
                    አንተ ታማኝና መልካም አገልጋይ በጥቂት ነገር ታማኝ ሆነህ ስለ ተገኘህ በብዙ ነገር ላይ
                    እሾምሃለሁ፤
                  </p>
                </p>
                <div className=" flex justify-center ">
                  <a
                    href="/join"
                    class="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-rose-900 hover:bg-rose-700 focus:ring-4 focus:ring-blue-300 "
                  >
                    Join Us
                    {/* Get started icon */}
                  </a>
                  <a
                    href="/contactus"
                    class="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-rose border border-rose-800 hover:text-white rounded-lg hover:bg-rose-900 focus:ring-4 focus:ring-gray-100 "
                  >
                    Contact us
                  </a>
                </div>
              </div>
              <div class="hidden lg:mt-0 lg:col-span-5 lg:flex">
                <img src="./image/senbet.png" alt="mockup" />
              </div>
            </div>
          </section>
        </SplideSlide>

        <SplideSlide>
          <section class="  ">
            <div class="grid max-w-screen-xl px-4  py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
              <div class="mr-auto place-self-center lg:col-span-7">
                <h1 class="text-center max-w-2xl mb-4 text-4xl font-extrabold leading-none md:text-5xl xl:text-6xl ">
                  <span className="text-rose-800 font-extrabold">Gebr</span>
                  -Heir Project
                </h1>
                <p class="max-w-2xl mb-6 font-extrabold text-gray-500 lg:mb-8 md:text-2xl lg:text-2xl text-center">
                  In your kind support, we grant little talents to many and
                  produce fruitful servants.
                </p>
                <p class="max-w-2xl mb-6 font-extralight text-gray-500 lg:mb-8 md:text-lg lg:text-xl ">
                  <span className="flex justify-center mb-3 items-center">
                    {" "}
                    <ImQuotesLeft className="" />{" "}
                  </span>
                  <p className=" flex justify-center text-center ">
                    Good and faithful servant; thou hast been faithful over a
                    few things, I will make thee ruler over many things:
                  </p>
                </p>
                <p class="max-w-2xl mb-6 font-extralight text-gray-500 lg:mb-8 md:text-lg lg:text-xl ">
                  {/* Your text content here */}
                </p>
                <div className=" flex justify-center ">
                  <a
                    href="/join"
                    class="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-rose-900 hover:bg-rose-700 focus:ring-4 focus:ring-blue-300 "
                  >
                    Join Us
                    {/* Get started icon */}
                  </a>
                  <a
                    href="/contactus"
                    class="inline-flex items-center justify-center px-5 py-3 text-base font-medium text-center text-rose border border-rose-800 hover:text-white rounded-lg hover:bg-rose-900 focus:ring-4 focus:ring-gray-100 "
                  >
                    Contact us
                  </a>
                </div>
              </div>
              <div class="hidden lg:mt-0 lg:col-span-5 lg:flex">
                <img src="./image/1 png.png" alt="mockup" />
              </div>
            </div>
          </section>
        </SplideSlide>
      </Splide>
    </div>
  );
}

export default HeroPage;
