/* eslint-disable jsx-a11y/alt-text */
import axios from "axios";
import React, { useEffect, useMemo } from "react";
import { useState } from "react";
// import axiosInstance from "../../../utils/axiosInterceptor";

function CheckComponent({
  decrementCount,
  incrementCount,
  setPrice,
  product,
  price,
}) {
  const [count, setCount] = useState(0);
  const tt = product.price * count;
  const _proudct = useMemo(() => product, [product]);
  useEffect(() => {
    setPrice((prevPrice) => {
      // Check if there is an existing entry in 'price' with the same 'index'
      const existingIndex = prevPrice.findIndex(
        (entry) => entry._proudct === _proudct.id
      );

      if (existingIndex !== -1) {
        // If an entry with the same 'index' exists, update it
        return prevPrice.map((entry, i) => {
          if (i === existingIndex) {
            return {
              ...entry,
              value: count * _proudct.price, // Update the value
            };
          }
          return entry;
        });
      } else {
        // If no entry with the same 'index' exists, create a new one
        return [
          ...prevPrice,
          {
            _proudct: _proudct.id, // Assuming 'index' is the unique identifier
            value: count * _proudct.price,
          },
        ];
      }
    });
  }, [count, _proudct]);

  function deleteUser(id) {
    axios
      .delete(`/pharmacy/checkout/${id}`, {
        method: "DELETE",
      })
      .then((result) => {
        result.json();
      });
  }
  return (
    <React.Fragment>
      <div class=" max-w-5xl md:space-x-6 xl:px-0">
        <div class="justify-between mb-6 rounded-lg bg-white p-6 shadow-md sm:flex sm:justify-start">
          <img
            src={product.imageUrl || "./image/1 png.png"}
            alt="product"
            class="w-full rounded-lg sm:w-40"
          />
          <div
            class="sm:ml-4 sm:flex sm:w-full sm:justify-between "
            // key={product.id}
          >
            <div class="mt-5 sm:mt-0">
              <h2 class="text-lg font-bold text-gray-900 mb-4">
                {product.package}
              </h2>
              <div class="text-lg font-bold text-gray-900" value={price}>
                {product.price} ብር
              </div>
            </div>
            <div class="mt-4 flex justify-between sm:space-y-6 sm:mt-0 sm:block sm:space-x-6">
              <div class="flex items-center border-gray-100">
                <span
                  class="cursor-pointer rounded-l bg-gray-100 py-1 px-3.5 duration-100 hover:bg-blue-500 hover:text-blue-50"
                  onClick={() => setCount((prev) => prev - 1)}
                >
                  -
                </span>
                <input
                  class="h-8 w-8 border bg-white text-center text-xs outline-none"
                  placeholder="2"
                  value={count}
                  onChange={(e) => setCount(parseInt(e.target.value))}
                />
                <span
                  class="cursor-pointer rounded-r bg-gray-100 py-1 px-3 duration-100 hover:bg-blue-500 hover:text-blue-50"
                  onClick={() => setCount((prev) => prev + 1)}
                >
                  +
                </span>
              </div>
              <div class="flex items-center space-x-4">
                <p class="text-sm " placeholder="200br">
                  {tt} ብር
                </p>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="h-5 w-5 cursor-pointer duration-150 hover:text-red-500"
                  onClick={() => deleteUser(product.id)}
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CheckComponent;
