import React from "react";
import { SplideSlide } from "@splidejs/react-splide";
import { TbTargetArrow } from "react-icons/tb";
import { GrAchievement } from "react-icons/gr";
import { BiTask } from "react-icons/bi";
import Navbarss from "../navbars/Navbarss";

function MassTrain() {
  return (
    <div>
      <Navbarss />

      <div className="pt-24">
        <SplideSlide>
          <div class="relative w-full ">
            <span
              id="blackOverlay"
              class="w-full h-full absolute opacity-50 bg-black"
            ></span>
            <img src="./image/bak.jpg" alt="telebirr" class="h-100 w-full " />
            <div class="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center text-gray-500 sm:gap-12 dark:text-gray-400">
              <h1 class="font-extrabold text-white text-3xl md:text-4xl lg:text-5xl font-heading shadow-2xl  ">
                Mass Training
              </h1>
              <p className="p-8 text-white text-center">
                {" "}
                We developed this program to offer group training sessions that
                focus on basic life skills, work culture, and professional
                <br></br> development for our benefactors. The goal of the mass
                training program is laying a solid <br></br>foundation for
                future trainings and initiatives that will benefit these
                individuals.
              </p>
            </div>
          </div>
        </SplideSlide>

        <section class=" dark:bg-gray-900">
          <div class="max-w-screen-xl px-4 py-4 mx-auto text-center lg:py-16 lg:px-6">
            <div class="text-center pb-12 rel">
              <h1 class="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-rose-700">
                Challenge
              </h1>
            </div>
            <figure class="max-w-screen-md mx-auto">
              <p class="text-md  text-gray-900 dark:text-white ">
                Currently, in Ethiopia’s crowded labor market, soft skills are
                in high demand by employers. They are also one of the strongest
                differentiating factors for the majority of Ethiopian youth
                struggling with prolonged unemployment. This is why Gebr-Heir
                created the mass training program - to provide our benefactors
                with a gift that differentiates them from the crowd and to
                provide some balance to the unbalanced labor market of the
                country.
              </p>
            </figure>
          </div>
        </section>
        <section class="  pt-8">
          <div class=" lg:col-span-7 flex justify-center items-center mt-6">
            <p class="max-w-2xl mb-4 text-2xl font-extrabold leading-none md:text-2xl xl:text-2xl dark:text-white ">
              <a href="/join" className="text-rose-800 font-normal text-center">
                Support Our Project
              </a>
            </p>
          </div>
          <div class="container mx-auto px-4 mb-6">
            <div class="flex flex-wrap">
              <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                <div class="max-w-md bg-white rounded-xl overflow-hidden cursor-pointer shadow-lg hover:shadow-2xl hover:scale-105 transform transition-all duration-500 p-2">
                  <div class="px-4 py-5 flex-auto">
                    <div class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                      <BiTask className="w-8 h-8" />
                    </div>
                    <h6 class="text-xl font-semibold">What we do</h6>
                    <p class="mt-2 mb-4 text-blueGray-500">
                      In partnership with accredited training and consulting
                      companies, we create and carry out soft skill training in
                      the form of mass training for Orthodox youth.
                    </p>
                  </div>
                </div>
              </div>
              <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                <div class="max-w-md bg-white rounded-xl overflow-hidden cursor-pointer shadow-lg hover:shadow-2xl hover:scale-105 transform transition-all duration-500 p-2">
                  <div class="px-4 py-5 flex-auto">
                    <div class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-rose-400">
                      <GrAchievement className="w-8 h-8 " />
                    </div>
                    <h6 class="text-xl font-semibold">Achievement </h6>
                    <p class="mt-2 mb-10 text-blueGray-500">
                      In our first round of mass training, we collaborated with
                      Qine Counseling to offer group training sessions on
                      fundamental life skills, workplace culture, and
                      professional growth to 48 Orthodox youth.
                    </p>
                  </div>
                </div>
              </div>
              <div class="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center ">
                <div class="max-w-md bg-white rounded-xl overflow-hidden cursor-pointer shadow-lg hover:shadow-2xl hover:scale-105 transform transition-all duration-500 p-2">
                  <div class="px-4 py-5 flex-auto">
                    <div class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                      <TbTargetArrow className="w-8 h-8" />
                    </div>
                    <h6 class="text-xl font-semibold">2023/24 target</h6>
                    <p class="mt-2 mb-20 text-blueGray-500">
                      Gebr-Heir intends to provide this training to about 2000
                      youths in Addis Abeba.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default MassTrain;
