import React from "react";
import { BsFacebook } from "react-icons/bs";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaTelegramPlane,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";
import { RiWhatsappFill } from "react-icons/ri";
import { ImLocation } from "react-icons/im";
import { MdEmail } from "react-icons/md";
function Footer() {
  return (
    <div>
      <footer class="p-4 bg-gray-50 sm:p-6 dark:bg-gray-800">
        <div class="mx-auto max-w-screen-xl">
          <div class="md:flex md:justify-between">
            <div class="mb-6 md:mb-0">
              <a href="/" class="flex items-center">
                <img
                  src="./image/1 png.png"
                  class="mr-3 w-40 h-40"
                  alt="Logo"
                />
              </a>
            </div>
            <div class="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
              <div>
                <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                  Go to
                </h2>
                <ul class="text-gray-600 dark:text-gray-400">
                  <li class="mb-4">
                    {" "}
                    <div>
                      <a href="./" className="hover:text-rose-700">
                        Home
                      </a>
                    </div>
                  </li>
                  <li class="mb-4">
                    {" "}
                    <div>
                      <a href="./about" className="hover:text-rose-700">
                        About us
                      </a>
                    </div>
                  </li>
                  <li class="mb-4">
                    {" "}
                    <div>
                      <a href="./program" className="hover:text-rose-700">
                        Programs
                      </a>
                    </div>
                  </li>
                  <li class="mb-4">
                    {" "}
                    <div>
                      <a href="./news" className="hover:text-rose-700">
                        News
                      </a>
                    </div>
                  </li>
                  <li>
                    {" "}
                    <div>
                      <a href="./contactus" className="hover:text-rose-700">
                        Contact us
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
              <div>
                <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                  Follow us
                </h2>
                <ul class="text-gray-600 dark:text-gray-400">
                  <li class="mb-4">
                    <div>
                      <span className="inline-flex mr-2">
                        <FaFacebookF className="text-rose-900 hover:text-rose-700 " />
                      </span>
                      <a
                        href="https://www.facebook.com/profile.php?id=61551149005409"
                        target=" _blank"
                      >
                        FaceBook
                      </a>
                    </div>
                  </li>
                  <li class="mb-4">
                    <span className="inline-flex mr-2">
                      <FaLinkedinIn className="text-rose-900 hover:text-rose-700 " />
                    </span>
                    <a
                      href="https://www.linkedin.com/in/gebrheir-humaniterian-022453296"
                      target=" _blank"
                    >
                      LinkedIn
                    </a>
                  </li>
                  <li class="mb-4">
                    <span className="inline-flex mr-2">
                      <FaWhatsapp className="text-rose-900 hover:text-rose-700 " />
                    </span>
                    <a
                      href="https://whatsapp.com/channel/0029Va7WSm3DTkJve0dsGw2K"
                      target=" _blank"
                    >
                      Whats app
                    </a>
                  </li>
                  <li class="mb-4">
                    <span className="inline-flex mr-2">
                      <FaTelegramPlane
                        className="text-rose-900 hover:text-rose-700 "
                        target=" _blank"
                      />
                    </span>
                    <a href="https://t.me/gebrheir">Telegram</a>
                  </li>
                  <li>
                    <span className="inline-flex mr-2">
                      <FaYoutube
                        className="text-rose-900 hover:text-rose-700 "
                        target=" _blank"
                      />
                    </span>
                    <a href="https://www.youtube.com/@Gebr-HeirProject">
                      YouTube
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <h2 class="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">
                  Address
                </h2>
                <ul class="text-gray-600 dark:text-gray-400">
                  <li class="mb-4">
                    <p>
                      {" "}
                      <span className="inline-flex mr-2">
                        <MdEmail className="text-rose-900 hover:text-rose-700 " />
                      </span>
                      Contactgebrehar@gmail.com
                    </p>
                  </li>
                  <li>
                    <div>
                      <span className="inline-flex mr-2">
                        <ImLocation className="text-rose-900 hover:text-rose-700 " />
                      </span>
                      Addis Ababa, Ethiopia
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <hr class="my-6 border-rose-800 sm:mx-auto dark:border-gray-700 lg:my-8 " />
          <div class="sm:flex sm:items-center sm:justify-between">
            <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">
              © 2023{" "}
              <a href="." class="hover:underline">
                Herani Tech
              </a>
              . All Rights Reserved.
            </span>
            <div
              className="flex sm:justify-center space-x-6 animate__animated
                 animate__pulse animate__delay-1s animate__infinite"
            >
              <a
                href="https://www.facebook.com/profile.php?id=61551149005409"
                target=" _blank"
                className="text-rose-900 hover:text-rose-700"
              >
                <BsFacebook className="h-5  w-5" />
              </a>
              <a
                href="https://whatsapp.com/channel/0029Va7WSm3DTkJve0dsGw2K"
                target=" _blank"
                className="text-rose-900 hover:text-rose-700"
              >
                <RiWhatsappFill className="h-5 w-5" />
              </a>
              <a
                href="https://www.linkedin.com/in/gebrheir-humaniterian-022453296"
                target=" _blank"
                className="text-rose-900 hover:text-rose-700"
              >
                <FaLinkedinIn className="h-5 w-5" />
              </a>
              <a
                href="https://t.me/gebrheir"
                className="text-rose-900 hover:text-rose-700"
                target=" _blank"
              >
                <FaTelegramPlane className="h-5 w-5" />
              </a>
              <a
                href="https://www.youtube.com/@Gebr-HeirProject"
                className="text-rose-900 hover:text-rose-700"
                target=" _blank"
              >
                <FaYoutube className="h-5 w-5" />
              </a>
            </div>
          </div>
        </div>
      </footer>

      <script src="https://unpkg.com/flowbite@1.4.7/dist/flowbite.js"></script>
    </div>
  );
}

export default Footer;
