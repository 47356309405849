import React from "react";
import Navbarss from "../navbars/Navbarss";
import "animate.css";
import Footer from "../home/Footer";
import WhatWeDos from "./WhatWeDo copy";
function Program({ scrollToSection }) {
  return (
    <div>
      <Navbarss />
      <div className="py-24">
        <div>
          <WhatWeDos scrollToSection={scrollToSection} />
        </div>

        <div class="text-center pb-4 mt-6" id="mass">
          <div class="grid max-w-screen-xl px-4   mx-auto lg:gap-8 xl:gap-0  lg:grid-cols-12">
            <div class="mr-auto place-self-center lg:col-span-7">
              <div className="flex justify-center mb-4">
                <h1 class="max-w-2xl mb-4 text-3xl font-extrabold leading-none md:text-3xl xl:text-3xl dark:text-white">
                  {" "}
                  <span className="text-rose-800 font-bold">Mass</span> Training
                </h1>
              </div>
              <p class="max-w-2xl mb-6 font-extralight text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
                We developed this program to offer group training sessions that
                focus on basic life skills, work culture, and professional
                development for our benefactors. The goal of the mass training
                program is laying a solid foundation for future trainings and
                initiatives that will benefit these individuals.
              </p>
              <a href="/masstrain">
                <button class=" w-full px-5 py-2 text-base font-medium text-white bg-gradient-to-r from-rose-700 to-rose-900 hover:to-rose-800 hover:from-rose-900 sm:w-auto hover:shadow-lg rounded-lg">
                  Read more
                </button>
              </a>
            </div>
            <div class="hidden lg:mt-0 lg:col-span-5 lg:flex">
              <img
                className=" rounded-full"
                src="./image/Picts for Galary/Mass training 5.jpg"
                alt="mockup"
              />
            </div>
          </div>
        </div>

        <section class=" dark:bg-gray-900 " id="vocation">
          <div class="grid max-w-screen-xl px-4   mx-auto lg:gap-8 xl:gap-0  lg:grid-cols-12">
            <div class="mr-auto place-self-center lg:col-span-7">
              <div class="hidden lg:mt-0 lg:col-span-5 lg:flex">
                <img
                  className="p-10 rounded-full"
                  src="./image/Picts for Galary/Vocational Training 3.jpg"
                  alt="mockup"
                />
              </div>
            </div>
            <div class=" lg:mt-0 lg:col-span-5 lg:flex">
              <div class="mr-auto place-self-center lg:col-span-7">
                <h1 class="max-w-2xl mb-4 text-xl font-bold leading-none md:text-2xl lg:text-xl dark:text-white">
                  <span className="text-rose-800 font-bold">
                    Customized Vocational
                  </span>{" "}
                  skills training
                </h1>
                <p class="max-w-2xl mb-6 font-extralight text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
                  We developed this program to provide vocational skills
                  training customized to the individual career interests of each
                  beneficiary. The training spans between three and six months
                  and equips our select group of youth with industry-sought and
                  applicable professional skills.
                </p>
                <p class="max-w-2xl mb-6 font-extralight text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
                  {/* Your text content here */}
                </p>
                <a href="/vocationatrain">
                  <button class="mb-6 w-full px-5 py-3 text-base font-medium text-white bg-gradient-to-r from-rose-700 to-rose-900 hover:to-rose-800 hover:from-rose-900 sm:w-auto hover:shadow-lg rounded-lg">
                    Read more
                  </button>
                </a>
              </div>
            </div>
          </div>
        </section>

        <section class=" dark:bg-gray-900 " id="seed">
          <div class="grid max-w-screen-xl px-4  mx-auto lg:gap-8 xl:gap-0 lg:grid-cols-12">
            <div class="mr-auto place-self-center lg:col-span-7">
              <h1 class="max-w-2xl mb-4 text-xl font-bold leading-none md:text-2xl lg:text-xl dark:text-white">
                <span className="text-rose-800 font-bold">Seed </span> Funding
              </h1>
              <p class="max-w-2xl mb-6 font-extralight text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
                This initiative will assist Orthodox youth who exhibit
                entrepreneurial aptitude, opportunity, and commitment but lack
                the necessary resources to realize their ideas.
              </p>
              <p class="max-w-2xl mb-6 font-extralight text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
                {/* Your text content here */}
              </p>
              <a href="/seeding">
                <button class=" w-full px-5 py-3 text-base font-medium text-white bg-gradient-to-r from-rose-700 to-rose-900 hover:to-rose-800 hover:from-rose-900 sm:w-auto hover:shadow-lg rounded-lg">
                  Read more
                </button>
              </a>
            </div>
            <div class=" lg:mt-0 lg:col-span-5 lg:flex">
              <div class="mr-auto place-self-center lg:col-span-7">
                <div class="hidden lg:mt-0 lg:col-span-5 lg:flex">
                  <img src="./image/senbet.png" alt="mockup" />
                </div>
              </div>
            </div>
          </div>
        </section>

        <div class="text-center pb-6 mt-4" id="digital">
          <div className="flex justify-center mb-4">
            <h1 class="max-w-2xl mb-4 text-4xl font-extrabold leading-none md:text-5xl xl:text-6xl dark:text-white">
              {" "}
              <span className="text-rose-800 font-extrabold">Digital</span>{" "}
              Business Network
            </h1>
          </div>
          <div className="flex justify-center">
            <p class="max-w-2xl mb-6 font-extralight text-gray-500 lg:mb-8 md:text-lg lg:text-xl sm:text:sm dark:text-gray-400">
              This program focuses on creating a digital business network for
              Orthodox youth. This will be done by creating an active online
              database that records members’ business activities and sparks
              business relationships between individuals and institutions.
            </p>
          </div>
          <a href="/businessnetwork">
            <button class=" w-full px-5 py-3 text-base font-medium text-white bg-gradient-to-r from-rose-700 to-rose-900 hover:to-rose-800 hover:from-rose-900 sm:w-auto hover:shadow-lg rounded-lg">
              Read more
            </button>
          </a>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default Program;
