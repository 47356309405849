import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { news } from "./News";
function DetailsSecond({ details2, setDetails2, caption, id }) {
  const exports = useRef();

  // eslint-disable-next-line no-unused-vars
  const change = useReactToPrint({
    content: () => exports.current,
    documentTitle: "user List",
    onAfterPrint: () => alert("Data Saved"),
  });

  const { title, content } = news.find((singleNews) => singleNews.id === id);
  return (
    <div
      className={`${!details2 && "hidden"} fixed inset-0 overflow-scroll z-50`}
    >
      <div className="flex items-center justify-center h-screen bg-black bg-opacity-25">
        <div class="p-8 rounded-xl bg-white shadow mt-24">
          <div ref={exports} style={{ width: "100%" }}>
            {" "}
            <div class="grid grid-cols-1 md:grid-cols-3">
              {" "}
              <div class="grid grid-cols-3 text-center order-last md:order-first mt-20 md:mt-0 mb-4">
                {" "}
              </div>{" "}
              <div class="relative">
                {" "}
                <div class="w-44 h-44 bg-white mx-auto rounded-full shadow-2xl absolute inset-x-0 top-0 -mt-24 flex items-center justify-center text-green-500">
                  <img
                    src="./image/1 png.png"
                    class="h-34 w-34 rounded-full"
                    alt="profile"
                  />
                </div>{" "}
              </div>{" "}
            </div>{" "}
            <div class="mt-20 text-center border-b pb-12">
              {" "}
              <h1 class="text-4xl font-medium text-gray-700">{title}</h1>{" "}
              <p class="mt-8 text-gray-500">{content}</p>{" "}
            </div>{" "}
          </div>
          <div className="flex justify-center">
            <button
              class="mt-4 text-white py-2 px-4 mr-4  rounded bg-gray-700 hover:bg-gray-800 shadow hover:shadow-lg font-medium transition transform hover:-translate-y-0.5"
              onClick={() => {
                setDetails2((prev) => !prev);
              }}
            >
              {" "}
              Back
            </button>{" "}
            <button
              class="mt-4 text-white py-2 px-4  rounded bg-rose-800 hover:bg-rose-700 shadow hover:shadow-lg font-medium transition transform hover:-translate-y-0.5"
              onClick={() => {
                setDetails2((prev) => !prev);
              }}
            >
              {" "}
              Finish
            </button>{" "}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailsSecond;
