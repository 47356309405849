import React from "react";

function Map() {
  return (
    <div>
      <section class="text-gray-600 body-font relative">
        <div class="absolute inset-0 bg-gray-300">
          <iframe
            width="100%"
            height="100%"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
            title="map"
            scrolling="no"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3941.241046614712!2d38.7444528!3d8.9499223!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b83e8ccde4053%3A0x4ba1a5fd99b38ada!2z4YyI4Yml4YitIOGKhOGIrSDhjI3hiaXhiKgg4Yig4YqT4YutIOGIm-GIleGJoOGIrSAtIEdlYnIgSGFpciBIdW1hbml0YXJpYW4gYXNzb2NpYXRpb24!5e0!3m2!1sen!2set!4v1693819411904!5m2!1sen!2set"
          ></iframe>
        </div>
        <div class="container px-5 py-24 mx-auto flex">
          <div class="lg:w-1/3 md:w-1/2 bg-white rounded-lg p-8 flex flex-col md:ml-auto w-full mt-10 md:mt-0 relative z-10 shadow-md">
            <h2 class="text-gray-900 text-lg mb-1 font-medium title-font">
              Contact Gebr-Heir Humanitarian Association
            </h2>
            <p class="leading-relaxed mb-5 text-gray-600">
              Gebr-Heir welcomes your questions, comments and concerns.
            </p>
            <div class="relative mb-4">
              <label for="email" class="leading-7 text-sm text-gray-600">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                class="w-full bg-white rounded border border-gray-300 focus:border-rose-400 focus:ring-2 focus:ring-rose-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <div class="relative mb-4">
              <label for="message" class="leading-7 text-sm text-gray-600">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                class="w-full bg-white rounded border border-gray-300 focus:border-rose-400 focus:ring-2 focus:ring-rose-200 h-24 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
              ></textarea>
            </div>
            <button class="text-white bg-rose-800 border-0 py-2 px-6 focus:outline-none hover:bg-rose-900 rounded text-lg">
              Submit
            </button>
            <p class="text-xs text-gray-500 mt-3">Thanks for your feedback</p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Map;
