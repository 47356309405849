import React from "react";

function EventSchedul() {
  return (
    <div className="h-fit overflow-hidden">
      <section class="bg-white dark:bg-gray-900 antialiased">
        <div class="max-w-screen-xl px-4 py-8 mx-auto lg:px-6 sm:py-16 lg:py-24">
          <div class="max-w-3xl mx-auto text-center">
            <h2 class="text-4xl font-extrabold leading-tight tracking-tight text-rose-700 dark:text-white">
              Event Schedule
            </h2>

            <div class="mt-4">
              <span
                title=""
                class="inline-flex items-center text-lg font-medium text-primary-600 dark:text-primary-500"
              >
                Learn more about our agenda
                <svg
                  aria-hidden="true"
                  class="w-5 h-5 ml-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </span>
            </div>
          </div>

          <table className="w-full text-sm mt-10 text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-white uppercase  dark:bg-gray-700 bg-slate-800 dark:text-gray-400">
              <tr>
                <th scope="col" className="py-3 px-6">
                  Date And TIme
                </th>
                <th scope="col" className="py-3 px-6">
                  Type Of Event
                </th>
                <th scope="col" className="py-3 px-6">
                  Event Location
                </th>
                <th scope="col" className="py-3 px-6">
                  Get way
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="bg-white border-b hover:border-l-4 hover:border-l-orange-500   dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50  dark:hover:bg-gray-600 ">
                <th
                  scope="row"
                  className="flex items-center py-4 px-6 text-gray-900 whitespace-nowrap dark:text-white "
                >
                  <div className="pl-3">
                    <div className="text-base font-semibold">
                      Every Other 15days Fri & Sat
                    </div>
                    <div className="font-normal text-gray-500 ">10:30 LT</div>
                  </div>
                </th>

                <td className="py-4  text-gray-500">Gebr-Heir Briefing</td>
                <td className="py-4 px-3">Gebr-Heir Head Office</td>
                <td className="py-4 px-6">
                  <td className="py-4 px-3 text-gray-500">Invitation</td>
                  <div className="flex items-center"></div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
}

export default EventSchedul;
