/* eslint-disable jsx-a11y/alt-text */
import { useEffect, useState } from "react";
import CheckOutOrder from "./CheckOutOrder";
import QRCodePopup from "./QrCode";
import CheckComponent from "./CheckProps";
// import LoadingSpinner from "../../basics/LoadingSpinner";
import axios from "axios";
import { useNavigate } from "react-router-dom";
export const packages = [
  {
    id: 1,
    imageUrl: "./image/1.png",
    package: "የህይወት ክህሎት ሥልጠና (Mass Training)",
    price: 2800,
  },
  {
    id: 2,
    imageUrl: "./image/2.png",
    package: "ምድብ 1 የሙያ ክህሎት ሥልጠና (Vocational skills training)",
    price: 9800,
  },
  {
    id: 3,
    imageUrl: "./image/2.png",
    package: "ምድብ 2 የሙያ ክህሎት ሥልጠና (Vocational skills training)",
    price: 14800,
  },
  {
    id: 4,
    imageUrl: "./image/2.png",
    package: "ምድብ 3 የሙያ ክህሎት ሥልጠና (Vocational skills training)",
    price: 18800,
  },
  {
    id: 5,
    imageUrl: "./image/3.png",
    package: "የአነስተኛ ካፒታል የስራ መነሻ (Seed Funding)",
    price: 27800,
  },
];
function PageCheck({ count, setCount, tt }) {
  const [price, setPrice] = useState([]);
  const [difference, setDifference] = useState();
  const [inComeTax, setIncomeTax] = useState();
  const [total, setTotal] = useState();
  const [vat, setVat] = useState(0.15);
  const [checkOuted, setcheckOuted] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);
  const [method, setMethod] = useState();
  const navigate = useNavigate();

  function back() {
    navigate("/join");
  }
  const toggleQRCode = () => {
    setShowQRCode(!showQRCode);
  };

  // const [count, setCount] = useState(1);
  function decrementCount() {
    // setCount((prevCount) => prevCount - 1);
  }

  function incrementCount() {
    // setCount((prevCount) => prevCount + 1);
  }

  useEffect(() => {
    setDifference(Math.abs(price * count));
  }, [price, count]);
  useEffect(() => {
    setIncomeTax(Math.abs(difference * vat));
  }, [difference, vat]);

  useEffect(() => {
    setTotal(Math.abs(inComeTax + difference));
  }, [difference, inComeTax]);

  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    // Update the current date every second
    const intervalId = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  const [outData, setOutData] = useState([]);
  const [loading, setLoading] = useState();
  const fetchUserInformation = () => {
    setLoading(true);
    axios
      .get(`/pharmacy/checkouts`)
      .then((resp) => {
        setOutData(resp.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching user information:", error);
      });
  };

  useEffect(() => {
    fetchUserInformation();
  }, []);

  const deleteUser = () => {
    axios
      .delete(`/pharmacy/delete`, {
        method: "DELETE",
      })
      .then((result) => {
        fetchUserInformation();
        result.json();
      })
      .then(() => {
        // After successful deletion, refresh the user information
        fetchUserInformation();
      })
      .catch((error) => {
        console.error("Error deleting user:", error);
      });
  };

  const allPrice = price.reduce((acc, pp) => (acc += pp.value), 0) * (1 + vat);
  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post(`/stock-out/sold`, updated).then(() => {});
  };

  const updated =
    outData &&
    Array.isArray(outData) &&
    outData.map((value) => {
      // console.log(value);
      const drudData = new Object();
      const findOut = price.find((val) => val.index === value.id);
      drudData.price = findOut?.value;
      drudData.quantity = findOut?.value / Number(value.drug.unitPrice);
      drudData.drugId = value.drug.id;
      drudData.transactionDate = currentDate;
      drudData.method = method;

      return drudData;
    });

  const handleRefreshClick = () => {
    window.location.reload();
  };
  const [fnames, setFnames] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [amount, setAmount] = useState("");

  const tx_ref = fnames;
  const public_key = "CHAPUBK-eazuwSNaWFVfPBnsi9lvdeN09SfHLefU";
  return (
    <div>
      <div className="pt-10 ">
        <h1 class="font-bold text-3xl md:text-4xl lg:text-5xl font-heading text-rose-700 text-center mb-8">
          Sponsorship
        </h1>
        <div className="mb-4 px-8 flex flex-col sm:flex-row">
          <div class="mx-4 md:mx-64">
            <h3 class="text-base md:text-lg font-medium leading-6 text-gray-900">
              <a
                href="https://drive.google.com/file/d/1wylcZ8_KPJpD9tpH1PrNqcStBVWpr1Js/view?usp=sharing"
                class="text-rose-800 font-extrabold underline"
                target=" _blank"
              >
                Click here {""}
              </a>
              to learn more about our sponsorship package!
            </h3>
          </div>

          <button
            className="ml-auto justify-center rounded-md border py-2 px-4 text-sm font-small text-slate-500  shadow-lg border-slate-400 hover:bg-rose-800 hover:text-white focus:outline-none  focus:ring-offset-2 mb-4 mr-8"
            onClick={back}
          >
            Back to site
          </button>
        </div>
        <div class="flex  ">
          <div className="w-1/2 p-4 ml-24">
            {/* <div>
              {loading ? (
                <LoadingSpinner /> // Display loading spinner if loading is true
              ) : (
                ""
              )}
            </div> */}

            {packages.map((product) => (
              <CheckComponent
                key={product.id}
                product={product}
                decrementCount={decrementCount}
                incrementCount={incrementCount}
                setPrice={setPrice}
                price={price}
              />
            ))}
          </div>
          <form onSubmit={handleSubmit}>
            <div className="w-1/2 p-4">
              <div class="mt-6 h-full rounded-lg border bg-white p-6 shadow-md md:mt-0 w-80 mb-4">
                <h1 class="mb-10 text-center text-2xl font-bold">Check Out</h1>

                {/* <div class="mb-2 flex justify-between">
                  <p class="text-gray-700">Subtotal</p>
                  <p class="text-gray-700">
                    {" "}
                    {price.reduce((acc, pp) => (acc += pp.value), 0)} br
                  </p>
                </div>
                <div class="flex justify-between">
                  <p class="text-gray-700">VAT</p>
                  <p
                    class="text-gray-700"
                    value={vat}
                    onChange={(e) => setVat(parseInt(e.target.value))}
                  >
                    15 %
                  </p>
                </div> */}
                {/* <div class="flex justify-between mt-2">
                  <p class="text-gray-700">Date</p>

                  <input
                    type="date"
                    class="text-gray-700"
                    {...currentDate.toLocaleString()}
                  />
                </div> */}

                <div class="flex justify-between">
                  <p class="text-lg font-bold">Total</p>
                  <div class="">
                    <p class="mb-1 text-lg font-bold">
                      {/* {price.reduce((acc, pp) => (acc += pp.value), 0) *
                      (1 - vat)}{" "} */}
                      {/* {allPrice} */}
                      {price.reduce((acc, pp) => (acc += pp.value), 0)} br
                    </p>
                  </div>
                </div>
                <hr class="my-2 mb-4" />
                <div className="">
                  <label class="  text-sm font-normal text-gray-700 tracking-wide">
                    Full Name
                  </label>
                  <input
                    class=" w-full text-base px-4  border-b border-gray-300 focus:outline-none rounded-md focus:border-rose-900"
                    type="text"
                    onChange={(e) => setFnames(e.target.value)}
                  />
                </div>
                <div>
                  <label class=" text-sm font-normal text-gray-700 tracking-wide">
                    Phone Number
                  </label>
                  <input
                    class=" w-full text-base px-4  border-b border-gray-300 focus:outline-none rounded-md focus:border-rose-900"
                    type="number"
                    onChange={(e) => setLname(e.target.value)}
                  />
                </div>

                <div>
                  <label class=" text-sm font-normal text-gray-700 tracking-wide">
                    Email
                  </label>
                  <input
                    class=" w-full text-base px-4  border-b border-gray-300 focus:outline-none rounded-md focus:border-rose-900"
                    type="email"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div>
                  <label class=" text-sm font-normal text-gray-700 tracking-wide">
                    Amount
                  </label>
                  <input
                    class=" w-full text-base px-4  border-b border-gray-300 focus:outline-none rounded-md focus:border-rose-900"
                    type="number"
                    value={price.reduce((acc, pp) => (acc += pp.value), 0)}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </div>
                <fieldset>
                  <div className="mt-2   space-y-4">
                    <div>
                      {/* <div>
                        <label class=" text-sm font-normal text-gray-700 tracking-wide">
                          Payment Method
                        </label>
                        <select
                          class=" w-full text-base px-4  border-b border-gray-300 focus:outline-none rounded-md focus:border-rose-900"
                          type="number"
                          value={method}
                          onChange={(e) => setMethod(e.target.value)}
                        >
                          <option value=""></option>
                          <option value="Chapa">Pay Chape</option>
                          <option value="On Promise">Pay By Promise</option>
                        </select>
                      </div>
                      <div className="flex">
                        {method === "On Promise" && (
                          <button
                            type="submit"
                            class="mt-2 mr-2 w-full rounded-md bg-slate-800 py-1.5 font-medium text-blue-50 hover:bg-slate-700"
                            onClick={() => {
                              deleteUser();
                              handleRefreshClick();
                            }}
                          >
                            Pay By Promise
                          </button>
                        )}
                        {method === "Chapa" && (
                          <button
                            onClick={() => {
                              setcheckOuted((prev) => !prev);
                              toggleQRCode();
                            }}
                            // onClick={toggleQRCode}
                            type="submit"
                            class="mt-2 w-full rounded-md bg-green-800 py-1.5 font-medium text-blue-50 hover:bg-green-700"
                          >
                            Pay Chapa
                          </button>
                        )}
                      </div> */}
                      <button
                        onClick={() => {
                          setcheckOuted((prev) => !prev);
                          toggleQRCode();
                        }}
                        // onClick={toggleQRCode}
                        type="submit"
                        class="mt-2 w-full rounded-md bg-green-800 py-1.5 font-medium text-blue-50 hover:bg-green-700"
                      >
                        Pay Chapa
                      </button>
                      <QRCodePopup
                        toggleQRCode={toggleQRCode}
                        showQRCode={showQRCode}
                        setShowQRCode={setShowQRCode}
                        fname={fnames}
                        lname={lname}
                        email={email}
                        amount={price.reduce((acc, pp) => (acc += pp.value), 0)}
                        tx_ref={tx_ref}
                        public_key={public_key}
                      />
                    </div>
                  </div>
                </fieldset>
                {/* <button
                  class="mt-6 w-full rounded-md bg-green-500 py-1.5 font-medium text-blue-50 hover:bg-green-600"
                  type="submit"
                  onClick={() => {
                    setcheckOuted((prev) => !prev);
                  }}
                >
                  Pay
                </button> */}
                {/* <div>
                  {isLoading ? (
                    <LoadingSpinner /> // Display loading spinner if loading is true
                  ) : (
                    ""
                  )}
                </div> */}
                <CheckOutOrder
                  checkOuted={checkOuted}
                  setcheckOuted={setcheckOuted}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PageCheck;
